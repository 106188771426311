.badge {
  margin-left: auto !important;
  sup {
    margin-top: 3px;
    background-color: $red;
    span {
      font-size: 10px;
      font-weight: 700;
    }
  }
  &.gray > sup {
    background-color: $mid-gray;
  }
}

.text-badge {
  font-size: 10px;
  border-radius: 3px;
  padding: 3px 4px;
  &.green {
    color: $green;
    border: 1px solid $green;
  }
  &.red {
    color: $red;
    border: 1px solid $red;
  }
}
