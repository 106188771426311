.account_member {
  &__wrapper {
    margin-bottom: 40px;
  }
  &__title {
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
  }
  &__item {
    max-width: 500px;
    &:not(:last-of-type){
      border-bottom: 1px dotted $light-gray;
    }
    padding: 20px 0;
    .item {
      &_inner__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &_name {
        &__wrapper {
          display: flex;
          align-items: center;
        }
        &__sub {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          margin-right: 15px;
          border-radius: 50px;
          border: 2px solid #fff;
          background-color: #fff;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: 1px;
          &._1 {
            background-color: $red;
            color: #fff;
          }
          &._2 {
            background-color: $orange;
            color: #fff;
          }
          &._3 {
            background-color: $link;
            color: #fff;
          }
          &._4 {
            background-color: $green;
            color: #fff;
          }
        }
        &__full {
          font-size: 16px;
          text-transform: capitalize;
          margin: 0;
        }
      }
      &_info {
        &__wrapper {
          display: flex;
          align-items: center;
        }
        &__liability {
          margin-right: 20px;

          .admin, .owner, .member {
            text-transform: capitalize;
            white-space: nowrap;
            padding: 5px;
            border-radius: 3px;
            color: $white;
            background: $discrete-text;
            font-size: 10px;
            font-weight: 700;
            line-height: 1;
          }

          .member, .admin {
            visibility: hidden;
            display: none;
          }

        }
        &__btn {
          border: 0;
          background-color: transparent;
          padding: 0;
          cursor: pointer;
          width: 20px;
          height: 10px;
          background-image: url("../../../images/svg/arrow-bottom.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          transition: 0.3s;
          &.arrowTop {
            transform: rotate(180deg);
          }
        }
      }
      &_dropdown {
        &__wrapper {
          display: block;
        }
        &__actions {
          margin-top: 10px;
          display: flex;
          .remove-btn {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.members {
  &__title {
    display: flex;
    align-items: center;
    h4 {
      font-size: 16px;
      text-transform: uppercase;
      margin: 0 40px 0 0;
    }
    span {
      color: $mid-gray;
      font-size: 14px;
    }
  }
  &__subscription-text {
    font-size: 14px;
    margin-bottom: 80px;
    @include for-size(md) {
      margin-bottom: 40px;
      width: 90%;
    }
  }
  &__manager {
    margin-bottom: 80px;
  }
}

.member {
  border-bottom: 1px dotted $light-gray;
  width: 100%;
  max-width: 390px;
  @include for-size(md) {
    width: 100%;
  }
  &__btns {
    display: flex;
    margin-bottom: 16px;
    @include for-size(xs) {
      flex-direction: column;
    }
  }
  &__dropdowns {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    width: 100%;
    padding: 20px 0;
    @include for-size(md) {
      align-items: flex-start;
      justify-content: space-between;
    }
    p {
      margin-left: 10px;
      margin-bottom: 0;
      @include for-size(md) {
        margin: 0;
      }
    }
    &__wrapper {
      width: 60%;
      display: flex;
      justify-content: space-between;
    }
    &__name {
      display: flex;
      align-items: center;
      width: 60%;
      @include for-size(md) {
        margin-bottom: 10px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__owner {
      width: 35%;
    }
    &__arrow {
      cursor: pointer;
    }
  }
  &_notification__wrapper {
    margin-bottom: 60px;
    max-width: 1000px;
    &.__box {
      border-radius: 3px;
      padding: 30px 20px;
      border: 1px solid $light-gray;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
      max-height: 460px;
      overflow-y: auto;
    }
  }

  &_notification {
    &__item {
      padding: 20px;
      display: flex;
      align-items: center;
      background: $orange;
      color: $white;
      justify-content: space-between;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
    &__desc {
      display: flex;
      align-items: center;
      width: 70%;
      p {
        margin-bottom: 0;
      }
      img {
        margin-right: 27px;
      }
    }
    &__actions {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    &__btn {
      cursor: pointer;
      border-radius: 3px;
      padding: 10px;
      border: 1px solid $white;
      color: #fff;
      height: auto;
      background-color: transparent;
      margin: 0 15px;
      &:hover {
        background-color: rgba(#fff, 0.1);
      }
      &__filled {
        cursor: pointer;
        border-radius: 3px;
        padding: 10px;
        border: 1px solid $white;
        height: auto;
        color: $orange;
        background: $white;
        margin-left: 20px;

        &:hover {
          background-color: rgba(#fff, 0.95);
        }
      }
    }
  }
}

.member_actions {
  &__dropdown {
    display: block;
    padding-left: 5px;
  }
}
