@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .editBlock {
    &_wrapper {
      padding: 15px 10px;
    }
    &_item {
      padding-bottom: 50px;
      &:before {
        width: 100%;
      }
    }
    &_head {
      flex-wrap: wrap;
      &__title {
        width: 100%;
        order: 2;
      }
      &__actions {
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 20px;
        order: 1;
      }
    }
    &_body {
      .services-offered {
        &_item {
          width: 100%;
          min-width: auto;
          max-width: 100%;
          margin-right: 0;
          padding: 15px 10px;
          .btn-custom__delete {
            img {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
    &_field,
    &_select,
    &_date-pecker,
    &_upload {
      &__item {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
      }
    }
    &_select__field {
      width: 100%;
    }
    &_field__item {
      .input {
        width: 100%;
      }
    }
    &_radio {
      &__wrapper {
        width: 100%;
        margin-bottom: 20px;
        padding-top: 0;
      }
    }
    &_upload {
      &__item {
        padding-top: 0;
      }
    }
    &_btn {
      &__clean {
        position: absolute;
        padding-top: 0;
        bottom: 10px;
        right: 0;
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}
