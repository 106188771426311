.contact-person {
  &_wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &_item {
    width: calc(33% - 20px);
    max-width: 340px;
    min-width: 310px;
    margin-bottom: 40px;
    margin-right: 20px;
    img {
      display: inline-block;
      margin-right: 10px;
    }
    div {
      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }
    span {
      color: #000;
      font-size: 14px;
    }
    &__mail {
      span {
        word-break: break-all;
      }
    }
  }
}
