.our-suppliers {
  .content-block_header {
    svg {
      width: 40px;
      height: 36px;
    }
  }
  &__tabs {
    margin-bottom: 80px;
  }
}

.favorites-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
