* {
  &.__uppercase {
    text-transform: uppercase;
  }
  &.__capitalize {
    text-transform: capitalize;
  }
}

h1.custom-title {
  font-size: 50px;
  font-weight: 900;
  line-height: 65px;
}

h3.custom-title {
  font-size: 24px;
  font-weight: 800;
  line-height: 31.2px;
}

h4.custom-title {
  font-size: 20px;
  font-weight: 700;
}

h5.custom-title {
  font-size: 16px;
  font-weight: 700;
}

h6.custom-title {
  font-size: 14px;
  font-weight: 700;
}

p.custom-text {
  font-size: 16px;
  font-weight: 400;
}
