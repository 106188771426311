.admin_header{
  &__wrapper{
    padding: 10px;
    background-color: #fff;
    box-shadow: 1px 1px 5px rgba(0,0,0, .2);
    min-height: 40px;
    display: flex;
    justify-content: flex-end;
  }
  &__login{
    &.__wrapper{
      display: flex;
      align-items: center;
      background-color: transparent;
      border: 0;
      box-shadow: none;
      cursor: pointer;
      &:hover{
        .admin_header__login.__email{
          color: #1ba2cc;
        }
        .admin_header__login.__image{
          box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
        }
      }
    }
    &.__email{
      transition: .2s;
    }
    &.__image{
      width: 40px;
      height: 40px;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #eee;
      transition: .2s;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
      img{
        max-width: 110%;
        max-height: 110%;
      }
    }
  }
}