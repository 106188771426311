@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .presentation-page-header {
    flex-wrap: wrap;
    &__left-side {
      width: 100%;
      min-width: auto;
      margin-bottom: 20px;
    }
    &__right-side {
      width: 100%;
      padding-left: 0;
    }
    &__titles {
      align-items: start;
    }
    &__about-org {
      flex-wrap: wrap;
    }
    &__name {
      margin-right: 20px;
    }
    &__name,
    &__info-org {
      width: 100%;
      padding: 0;
      border: 0;
    }
    &__edit-btn {
      margin-top: 5px;
    }
    &__favorite {
      width: 50%;
      margin-top: 30px;
      text-align: center;
    }
    &__select {
      width: 50%;
      margin-top: 30px;
      padding-left: 0 !important;
      text-align: center;
    }
  }

  .presentation-header-edit.presentation-page-header {
    &__titles {
      flex-wrap: wrap;
      margin-bottom: 0;
    }
    &__actions {
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 20px;
      order: 1;
    }
    &__about-org {
      width: 100%;
      order: 2;
    }
    &__input-name {
      width: 100%;
      margin-bottom: 10px;
    }
    &__info-org {
      margin-bottom: 10px;
    }
    &__input-description {
      min-height: 100px;
    }
    &__input-description.request {
      min-height: 180px;
    }
  }

  .presentation-page {
    flex-wrap: wrap;
    &__left-side {
      width: 100%;
    }
    &__right-side {
      width: 100%;
      padding-left: 0;
      &__tabs {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 50px;
        padding-top: 30px;
        border-top: 1px dotted $light-gray;
        .tab {
          font-size: 14px;
          margin: 0 10px 10px 10px;
        }
      }
    }
    &__quick-facts {
      width: 170px;
      margin: 0 auto;
    }
  }
}
