.team-tab {
  display: flex;
  @include for-size(md) {
    flex-direction: column;
  }
  &__left-side {
    width: 65%;
    padding-right: 40px;
    @include for-size(md) {
      width: 100%;
      padding-right: 0;
    }
    .add-btn {
      border: none;
    }
  }
}

.member-team {
  margin-bottom: 26px;
  &__user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  &__user {
    display: flex;
    align-items: flex-end;
    @include for-size(md) {
      flex-direction: column;
      align-items: flex-start;
    }
    img {
      margin-right: 16px;
    }
  }
  &__teamStatus {
    display: inline;
    font-size: 12px;
    text-transform: uppercase;
    margin-right: 20px;
  }
  &__desc {
    font-size: 12px;
    color: $discrete-text;
  }
  &__check {
    img {
      margin-left: 40px;
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    @include for-size(md) {
      flex-direction: column;
      align-items: flex-start;
      .make-btn {
        margin-bottom: 10px;
      }
    }
    button:first-child {
      margin-right: 20px;
    }
  }
}
