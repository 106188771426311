.inviteOnboarding {
  &_wrapper {
    min-height: 100vh;
    padding-bottom: 100px;
    background-color: $off-white;
  }

  &_header {
  }

  &_title {
    margin: 0 auto 80px;
    width: 400px;
    .custom-title {
      font-size: 50px;
      line-height: 65px;
      letter-spacing: -5%;
      font-weight: 900;
      margin-bottom: 20px;
    }
    .custom-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 0;
    }
  }

  &_steps {
    &__wrapper {
      width: 400px;
      margin: 0 auto;
    }
  }

  &_points__wrapper.ant-steps {
    margin-bottom: 30px;
    .ant-steps-item {
      &-icon {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        .ant-steps-icon{
          top: 0;
        }
    }
      &-wait {
        .ant-steps-item-icon {
          background-color: $light-gray;
          color: $mid-gray;
          border-color: $mid-gray;
        }
      }
      &-process,
      &-active {
        .ant-steps-item-icon {
          background-color: $link;
          color: #fff;
          border-color: $link;
        }
      }
      &-content {
        .ant-steps-item-title {
          &:after {
            top: -20px;
            left: calc(100% + 40px);
          }
        }
      }
      &-finish {
        .ant-steps-item-icon {
          border-color: $link;
          svg {
            color: $link;
          }
        }
        .ant-steps-item-title {
          &:after {
            background-color: $link !important;
          }
        }
      }
    }
  }

  &_form__wrapper {
    .settings-form {
      .field {
        .input {
          width: 100%;
        }
        .react-number-input-search {
          width: 100%;
          height: 60px;
          background: $white;
          border: none;
          font-size: 14px;
          line-height: 17px;
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
          outline: none;
          padding-left: 60px;
        }
      }
    }
    .custom-control.custom-switch {
      .ant-switch-checked {
        background-color: $link;
      }
    }
  }

  &_form {
    &__one {
      .inviteOnboarding_btn__wrapper {
        justify-content: flex-end;
      }
    }
  }

  &_btn {
    &.ant-btn {
      min-width: 120px;
      font-size: 1.25rem;
      min-height: 50px;
      background-color: $link;
      border-color: $link;
      color: $white;
      &:hover {
        background-color: rgba($link, 0.8);
        color: #fff;
        border-color: $link;
      }
    }
    &__wrapper {
      width: 400px;
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
    }
  }
}
