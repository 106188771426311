.firstSetup {
  &__logo {
    background: transparent;
    border: none;
    @include for-size(md) {
      text-align: left;
      padding: 0;
    }
  }

  &__body {
    max-width: 60%;
    margin: 0 auto;
    padding-bottom: 10vh;
    @include for-size(l) {
      max-width: 80%;
    }
    @include for-size(md) {
      max-width: 100%;
      padding: 0 20px;
    }
    .top-section {
      margin-left: 20px;
    }

    .decorative-search {
      margin-top: 0;
      margin-bottom: 20px;
      @include for-size(md) {
        margin-top: 40px;
      }
    }

    .info-chunk {
      margin-bottom: 40px;
    }

    .topic {
      width: auto;
      margin-bottom: 20px;
      border-radius: 3px;
      font-weight: 800;
      font-size: 18px;
      text-transform: uppercase;

      span,
      svg {
        vertical-align: middle;
      }

      svg {
        height: 30px;
        width: auto;
        margin-right: 10px;
      }
    }
  }

  &__filters {
    .multi-select {
      .ant-select-selector {
        background-color: transparent;
      }
    }
  }

  &__language {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
    margin-bottom: 84px;

    .decorative-search {
      @include for-size(l) {
        margin-bottom: 20px;
      }
    }

    @include for-size(l) {
      flex-direction: column;
    }
    @include for-size(md) {
      margin-bottom: 44px;
    }
  }

  &__text {
    margin-bottom: 40px;
    @include for-size(md) {
      margin-bottom: 50px;
    }
    @include for-size(xs) {
      font-size: 11px;
    }
  }

  &__btn {
    display: flex;
    margin-bottom: 40px;
    margin-top: 40px;
    @include for-size(xs) {
      flex-direction: column;
      margin-bottom: 70px;
    }

    .btn {
      @include for-size(md) {
        height: 50px;
        padding: 0;
      }
    }

    .btn-transparent {
      height: 61px;
      padding: 20px 80px;
      text-align: center;
      display: block;
      margin-right: 40px;
      @include for-size(md) {
        margin-bottom: 20px;
        padding: 0;
        height: 50px;
      }
      @include for-size(xs) {
        width: 100%;
      }
    }
  }
}

.first-setup_selection {
  &__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    .multi-select {
      .ant-select-selection-item:first-child {
        order: -1;
      }
    }
  }
  &__item-wrap {
    margin-right: 40px;

    &.__with-dash {
      .blob-title {
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      .blob-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
      }
    }
  }
}

.firstSetup {
  .first-setup_selection {
    &__wrapper {
      flex-direction: column;
      .multiselect-custom_title,
      .blob-title,
      .selection__title {
        display: none;
      }
    }
    &__item-wrap {
      margin-bottom: 40px;
      margin-right: 0;
    }
  }

  .organization-filter_selections__wrapper {
    display: flex;
    flex-direction: column;
  }
  .more-setup-selections_wrapper {
    order: 1;
  }

  .first-setup_selection__wrapper {
    order: 2;
    margin-top: 40px;
  }

  .firstSetup__btn {
    order: 3;
  }

  .editBlock_body {
    .topic {
      display: none;
    }
  }
}
