.input {
  height: 60px;
  background: $white;
  border: none;
  font-size: 14px;
  line-height: 17px;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  outline: none;
  padding-left: 20px;
  //::-webkit-input-placeholder {
  //  font-weight: normal;
  //  color: $placeholder;
  //}
}

input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.label {
  font-size: 14px;
  line-height: 130%;
  display: block;
  margin: 20px 0 10px 20px;
}


.form-field_wrapper{
  margin-bottom: 20px;
  .ant-input{
    margin-bottom: 5px;
    width: 100%;
    height: 60px;
    min-width: 130px;
    padding-left: 20px;
    background: #ffffff;
    border: none;
    font-size: 14px;
    line-height: 17px;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    outline: none;
  }
}