.order-inside {
  background: $white;
  margin-bottom: 40px;
  width: 82%;
  @include for-size(l) {
    width: 100%;
  }

  a {
    color: $link;
  }

  &__header {
    padding: 20px 40px;
    @include for-size(md) {
      padding: 20px;
    }
  }

  &__titles {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      margin-right: 20px;
      @include for-size(md) {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 0;
      @include for-size(md) {
        font-size: 17px;
      }

      &:last-child {
        margin-left: 11px;
      }
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    @include for-size(md) {
      flex-direction: column;
    }
  }

  .about-project {
    display: flex;
    @include for-size(md) {
      flex-direction: column;
    }

    &__icon {
      display: flex;

      &:before {
        background-repeat: no-repeat;
        margin-right: 11px;
      }
    }

    a {
      margin-right: 40px;
      @include for-size(md) {
        margin-bottom: 10px;
      }

      &:before {
        content: url("../../images/svg/map-marker.svg");
      }
    }

    &__apartment {
      margin-right: 40px;
      @include for-size(md) {
        margin-bottom: 10px;
      }

      &:before {
        content: url("../../images/svg/apartment.svg");
      }
    }

    &__date {
      &:before {
        content: url("../../images/svg/calendar-full.svg");
      }
    }
  }

  .time-project {
    font-size: 14px;
    @include for-size(md) {
      margin: 20px 0;
    }

    span:first-child {
      margin-right: 20px;

      time {
        margin-left: 7px;
        color: $green;
      }
    }

    span:last-child {
      color: $mid-gray;

      time {
        margin-left: 7px;
      }
    }
  }

  &__body {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    padding: 20px 40px;
    min-height: 806px;
    @include for-size(md) {
      padding: 20px;
      width: 95%;
      margin: 0 auto;
    }
  }

  &__tablist {
    margin-bottom: 50px;
    @include for-size(md) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      button {
        margin-bottom: 10px;
      }
    }
  }
}
