.overview-tab {
  display: flex;
  @include for-size(md) {
    flex-direction: column;
  }

  &__header {
    padding-bottom: 26px;
    margin-bottom: 20px;
    border-bottom: 1px dotted $light-gray;
  }

  &__body {
    p {
      line-height: 130%;
      margin-bottom: 20px;
      color: $discrete-text;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__left-side {
    width: 65%;
    padding-right: 40px;
    @include for-size(md) {
      width: 100%;
      padding-right: 0;
    }
  }

  &__contacts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    @include for-size(md) {
      flex-direction: column;
      div:first-child {
        margin-bottom: 10px;
      }
    }

    span {
      color: $mid-gray;
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
  }

  &__contacts-links {
    display: flex;
    @include for-size(md) {
      flex-direction: column;
      margin-top: 20px;
    }

    a {
      display: flex;

      &:before {
        background-repeat: no-repeat;
        margin-right: 10px;
      }
    }

    &__telephone {
      margin-right: 40px;

      &:before {
        content: url("../../images/svg/phone.svg");
      }
    }

    &__letter {
      &:before {
        content: url("../../images/svg/letter.svg");
      }
    }
  }
}
