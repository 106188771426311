.main-sidebar {
  &_wrapper {
    min-width: 200px;
  }
  &_list {
    display: flex;
    flex-direction: column;
  }
  &_section {
    padding: 35px 0px 5px 18px;
    font-weight: 700;
    &.blue {
      color: $link;
    }
    &.red {
      color: $orange;
    }
  }
  &_btn {
    color: #000000;
    font-size: 16px;
    border: none;
    padding: 12px 0px 12px 6px;
    background: transparent;
    display: flex;
    align-items: center;
    border-radius: 3px;
    &.active {
      background: #efefef !important;
      font-weight: bold;
      &:hover {
        cursor: default;
        color: #000000;
      }
    }
    img, svg {
      margin: 0 20px 0 11px;
      width: 20px;
      height: 20px;
    }
  }
}

.abs-bg_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
  z-index: 100;
  &.showSidebar {
    height: 100%;
    opacity: 1;
    transition: none;
    animation: fadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    pointer-events: auto;
  }
}
