.footer {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  &__info {
    width: 85%;
    margin-left: auto;
    display: flex;
    padding: 40px 0;
    @include for-size(md) {
      padding: 40px 20px;
      flex-direction: column;
      width: 100%;
    }
  }
  &__logo {
    margin-right: auto;
    margin-bottom: 11px;
  }
  &__links {
    width: 20%;
    display: flex;
    flex-direction: column;
    @include for-size(md) {
      width: 100%;
      margin-bottom: 20px;
    }
    a {
      color: $black;
      line-height: 130%;
      margin-bottom: 10px;
    }
  }
}
