.ant-table-wrapper {
  &.permissions {
    .ant-table-row {
      min-height: 41px;
      height: 41px;
      .subscription-column span.quantity-block {
        display: none;
      }
      .subscription-column span.quantity-hover {
        display: block;
      }
      &:hover {
        .subscription-column span.quantity-block {
          display: block;
        }
        .subscription-column span.quantity-hover {
          display: none;
        }
      }
    }
  }
  &.created-rfps {
    .ant-table-row {
      .description-column {
        word-break: break-all;
      }
    }
  }
}
