.time-tab {
  padding-top: 40px;

  &__header {
    span {
      font-weight: bold;
      font-size: 12px;
      color: $mid-gray;
      text-transform: uppercase;
    }

    div {
      margin-top: 20px;
      margin-bottom: 40px;
      display: flex;
      @include for-size(md) {
        flex-direction: column;
      }

      p:first-child {
        margin-right: 40px;
        @include for-size(md) {
          margin-bottom: 10px;
        }
        span {
          color: $green;
        }
      }

      span {
        margin-left: 8px;
      }
    }
  }

  &__body {
    &__title {
      font-weight: bold;
      font-size: 12px;
      color: $mid-gray;
      text-transform: uppercase;
      margin-bottom: 22px;
      display: block;
    }

    &__titles {
      display: flex;
    }

    &__name {
      min-width: 60px;
    }

    &__hours {
      min-width: 44px;
    }

    &__date {
      font-weight: bold;
      display: flex;
      margin-bottom: 20px;
      min-width: 107px;

      &:after {
        content: url("../../images/svg/mark.svg");
        background-repeat: no-repeat;
        margin-left: 20px;
      }
    }
  }
}
