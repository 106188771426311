.selection {
  &__title {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #000000;
  }
  .ant-select {
    background: $link;
    color: $white;
    &.ant-select-open { 
      .ant-select-selection-item {color: $dimmed-opaque-white }
    }
    height: 40px;
    width: max-content;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    font-style: italic;
    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
      padding-right: 24px !important;
      padding-left: 10px !important;
      @include for-size(md) {
        padding: 0;
      }
    }
    .ant-select-selection-placeholder {
      opacity: 1;
      color: #fff;
    }
    .ant-select-arrow {
      top: 41%;
      right: 17px;
    }
  }
  .anticon-down {
    background: white;
    color: $link;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.multi-select {
  &__wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    p{
      width: 100%;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-left: 10px;
    margin-bottom: 20px;
    color: #000000;
  }

  .ant-select-selection-search-input {
   color: transparent;
  }

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    outline: none;
    display: flex;
    flex-flow: wrap;
    padding-left: 100px !important;

    .ant-select-selection-item {
      margin: 0 0 8px 8px;
      background: $link;
      color: $white;
      height: 40px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      cursor: pointer;

      .anticon-close {
        background: white;
        color: $link;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 11px;
        }
      }
    }
  }

  &__add-btn {
    color: $link;
    padding: 9px;
    background: $white;
    border: 1px solid $link;
    border-radius: 40px;
    display: flex;
    cursor: pointer;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;

    &:before {
      content: url("../../../images/svg/plus.svg");
      width: 20px;
      height: 20px;
      background-size: contain;
      margin-right: 10px;
    }
  }
}

.ant-select-dropdown {
  width: auto !important;
  max-width: 320px !important;
  min-width: 210px !important;
  &.addable-search-select {
    min-width: 320px !important;
    .left-value {
      float: left;
      width: 55%;
      height: 100%;
      padding-right: 5px;
      border-right: 1px dotted $light-gray;
      font-weight: 600;
    }
    .right-value {
      float: right;
      padding-left: 5px;
      height: 100%;
      width: 45%;
    }
  }
  .ant-select-item-option-content {
    white-space: pre-wrap !important;
  }
  .ant-select-selection-search-input {
    color: transparent;
  }
}

.addable-search-select {
  &_wrapper {
    width: 236px;
    flex-shrink: 0;
    .ant-select.ant-select-single {
      .ant-select-selector {
        width: 236px !important;
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px !important;
        height: 40px !important;
        border: none !important;
        &:hover,
        &:active {
          border: none !important;
        }
        span {
          font-size: 16px !important;
          padding: 0px 5px 0px 10px !important;
          border-radius: 10px !important;
          line-height: 40px;
        }
      }
    }
  }
}

.selections-group {
  &_wrapper{
    margin-bottom: 40px;

    &.__open{
      margin-bottom: 10px;
      .multiselect-custom_wrapper{
        margin-bottom: 30px;
      }
    }
    &:not(.__open) {
      margin-bottom: 20px;
    }
  }
  &_inner-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  &_switch{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &__title{

    }
    &__label{
      margin-left: 20px;
      cursor: pointer;
      color: rgba(0,0,0,0.3);
      transition: all 0.2s ease-in-out;
      &._checked{
        color: #000;
      }
      &._disabled{
        cursor: not-allowed;
      }
    }
  }
  &_selects{
    margin-right: 50px;
    &:not(:last-child){
      margin-bottom: 10px;
    }
  }
  &_sub-title{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    width: 100%;
    line-height: 1;
  }
}

.more-setup-selections{
  &_wrapper{
    padding: 20px 20px 1px 20px;
    background: #FAFAFA;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    &._edit{
      background-color: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    }
  }
  &_sub-title{
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 3px;
    padding: 5px 5px 4px 5px;
  }
}
