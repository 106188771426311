.settings-page {
  &_wrapper {
    background-color: $off-white;
    padding-bottom: 100px;
    overflow: hidden;
  }
  &__desc {
    font-size: 14px;
    text-transform: uppercase;
  }

  &__header {
    .header {
      @include for-size(md) {
        flex-direction: column;
      }
    }
  }

  &__title {
    margin-bottom: 80px;
    .custom-title {
      letter-spacing: -2px;
    }
  }

  &__form {
    h6 {
      margin-bottom: 10px;
    }
    .settings-form {
      .field {
        margin-bottom: 20px;
        .label {
          margin: 0 0 10px 20px;
          font-size: 14px;
          line-height: 18.2px;
        }
      }
    }
  }

  &__info {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    @include for-size(md) {
      width: 100%;
      padding: 0 20px;
    }
    input {
      width: 100%;
      margin-bottom: 5px;
    }
    span {
      display: block;
      margin-bottom: 80px;
    }

    .payment-title {
      margin-top: 49px;
      margin-bottom: 20px;
    }

    .form {
      input {
        width: 100%;
      }
    }
  }
}
