.request {
  background: $white;
  margin-bottom: 40px;
  border-radius: 3px;
  &__tabs {
    margin-bottom: 40px;
  }
  .content-block {
    &_header {
      margin-bottom: 0px;
      min-height: 156px;
      padding: 40px;
      svg {
        margin: 4px 20px 0px 0px;
      }
    }
    &_wrapper {
      min-height: 50vh;
    }
    &_title {
      line-height: 31px;
    }
  }
  &_head__btn {
    margin-left: auto;
    margin-top: -10px;
  }
  .view-header {
    &._wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    &__title {
      font-size: 24px;
      font-weight: 800;
      &.removed, &.draft {
        color: $orange;
      }
    }
    &__above-title {
      color: $mid-gray;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }
    &__above-title {
      color: $mid-gray;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }
    &__above-title {
      color: $mid-gray;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }
    &__address {
      color: $link;
    }
    &__status-row,
    &__button-row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    &__status-row {
      font-size: 12px;
      line-height: 41px;
      .__closed-offer {
        color: $red;
      }
      .created {
        color: $mid-gray;
      }
      &__button {
        border: none;
        img {
          margin-right: 0px;
        }
      }
    }
    &__button-row {
      .status {
        &.draft{
          color: $orange;
        }
        &.published {
          color: $green;
        }
        &.removed {
          color: $red;
        }
      }
      &__button {
        font-size: 14px;
        &__remove {
          color: $red;
          border-color: $red;
          font-size: 14px;
        }
      }
    }
  }
  .request-edit-block {
    .main-fields_wrapper {
      display: flex;
      flex-flow: row wrap;
      &__input {
        .label {
          margin-top: 0px;
        }
        margin-bottom: 10px;
      }
      &__input.name .input {
        width: 400px;
      }
      .editBlock_select__field {
        width: 200px;
      }
      .editBlock_date-pecker__item {
        max-width: 180px;
      }
    }
    form > .form > label {
      margin-top: 10px;
    }
  }
  .request-view-block {
    &_contacts {
      display: flex;
      align-items: center;
      &_title {
        font-size: 16px;
        font-weight: 600;
      }
      &_initials {
        height: 20px;
        width: 20px;
        font-weight: 700;
        font-size: 8px;
        line-height: 20px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        text-align: center;
        user-select: none;
      }
    }
  }

  .recipients-tab {
    &_zone {
      display: flex;
      flex-flow: row nowrap;
    }
    &_title {
      font-size: 12px;
      font-weight: 700;
      margin: 40px 0px 10px 0px
    }
    &_overall-status {
      /* display: flex;*/
      /* hiding this til we have filter matches */
      display: none;
      font-size: 16px;
      border-top: 1px dotted $light-gray;
      &_title {
        font-weight: 600;
      }
      &_count {
        color: $discrete-text;
      }
    }
    &_head {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 10px 10px 0px 0px;
      &__empty {
        width: 80px;
      }
      &__name {
        width: 55%;
      }
      &__column {
        width: 15%;
        text-align: center;
      }
    }
    &_wrapper .empty-data {
      text-align: center;
      font-weight: 700;
    }
    &_wrapper .requests-card_body {
      margin: 0;
      border-bottom: 1px dotted $light-gray;
    }
  }

  .proposals-tab {
    &_wrapper {
      .empty-data {
        text-align: center;
        font-weight: 700;
      }
    }
    &_head {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 10px 10px 0px 10px;
      margin-left: -10px;
      &__name {
        &.__wrapper {
          width: 18%;
          &_grow {
            flex-grow: 1;
          }
        }
        &.__select {
          .ant-select {
            min-width: 55px;
            .ant-select-selector {
              background-color: transparent;
              border: 0;
              font-weight: 700;
              padding-left: 2px;
              &:after {
                display: none;
              }
              &:hover {
                background-color: #efefef;
              }
              .ant-select-selection-item {
                position: relative;
                padding-right: 30px;
                &:after {
                  position: absolute;
                  content: "";
                  display: block;
                  top: calc(50% + 2px);
                  right: 3px;
                  width: 10px;
                  height: 10px;
                  transform: translateY(-50%);
                  border: 6px solid transparent;
                  border-top: 6px solid $link;
                  transition: 0.2s;
                  visibility: inherit;
                }
              }
            }
            &.ant-select-open,
            &.ant-select-focused {
              .ant-select-selector {
                box-shadow: none !important;
              }
            }
            .ant-select-arrow {
              display: none;
            }
          }
        }
      }
      &__column {
        width: 15%;
        &_grow {
          flex-grow: 1;
        }
      }
    }
    &_body {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 10px;
      margin-left: -10px;
      border: 1px solid transparent;
      border-bottom: 1px dotted $light-gray;
      border-radius: 3px;
      transition: 0.3s;
      color: $black;
      &:hover {
        cursor: pointer;
        border-color: rgba($link, 0.3);
        color: $link;
        div {
          color: $link !important;
        }
      }

      &__name {
        &.__wrapper {
          display: flex;
          width: 18%;
          &_grow {
            flex-grow: 1;
          }
        }
        &.__unread {
          font-weight: 700;
        }
      }
  
      &__column {
        &.__wrapper {
          width: 15%;
          &_grow {
            flex-grow: 1;
          }
          div {
            color: $black;
          }
        }
        &.__unread {
          font-weight: 700;
        }
      }
    }
  }

  .proposal-card {
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 700;
      img {
        cursor: pointer;
        color: $link;
      }
      .green-button {
        color: $white;
        background-color: $green;
      }
      .red-button {
        color: $white;
        background-color: $red;
      }

      .evaluating-button, .no-thanks-button, .create-agreement-button {
        span {
          flex: 1;
        }
      }

      .evaluating-button, .no-thanks-button {
        width: 120px;
        height: 32px;
        padding: 5px 10px 10px 5px;
        transition: background-color 0.5s ease;
      }

      .create-agreement-button {
        padding: 5px 10px;

        &:hover {
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
          height: 42px;
          padding: 10px 15px;
          margin: -5px;
        }
      }

    }
    .seller-side, .our-side {
      width: 50%;
      border-top: 1px dotted $light-gray;
    }
    .seller-side {
      border-right: 1px dotted $light-gray;
      padding-right: 40px;
      &_message-header {
        display: flex;
        align-items: center;
        margin: 80px 0px 20px -25px;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .our-side {
      padding-left: 40px;
      .edit-price-adjustments_wrapper {
        .editBlock_item {
          padding: 0px;
          margin-bottom: 0px;
          &::before {
          border: none;
          }
        }
        .editBlock {
          &_head {
            justify-content: flex-end;
          }
        }
        .edit-price-adjustments-input {
          margin-top: 10px;
          height: 37px;
          margin-right: 10px;
          width: 100%;
        }
        .editBlock_flex__wrap {
          justify-content: space-between;
          align-items: flex-start;
          &__left {
            display: flex;
            flex-flow: row wrap;
            width: 93%;
          }
          &__right {
            display: flex;
            margin-top: 17px;
          }
          .form {
            display: flex;
            flex-flow: column nowrap;
            padding-right: 10px;
            &:first-child {
              width: 40%;
            }
            &:last-child {
              width: 60%;
            }
            p {
              margin-bottom: 0px;
            }
          }
        }
      }
    }

    .main-info {
      display: flex;
      &_block {
        width: 50%;
        padding-top: 40px;
        &.detailed {
          width: 100%;
        }
        &_header {
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 700;
          color: $mid-gray;
          margin-bottom: 5px;
        }
        &_date {
          font-size: 12px;
          color: $discrete-text;
        }
        &_name {
          font-size: 24px;
          font-weight: 800;
          color: $link;
        }
        &_value {
          font-size: 24px;
          font-weight: 800;
          color: $green;
          &.our-value {
            color: $orange;
          }
          span {
            font-size: 14px;
            font-weight: 700;
          }
        }
        &_detailed-values {
          font-size: 14px;
          color: $black;
          div div {
            display: inline-block;
            width: 80px;
          }
          span {
            color: $mid-gray;
          }
        }
        .button-edit {
          margin-left: auto;
        }
      }
      &_contacts {
        display: flex;
        align-items: center;
        padding-top: 40px;
        &_initials {
          height: 40px;
          width: 40px;
          font-weight: 700;
          font-size: 16px;
          color: $white;
          line-height: 36px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          border: 2px solid $white;
          border-radius: 50%;
          background-color: $green;
          text-align: center;
          user-select: none;
        }
        &_name {
          font-size: 16px;
          margin-top: -3px;
        }
      }
    }
  }

  .contract-tab {
    &__title {
      font-size: 16px;
      font-weight: 700;
    }
    &_documents {
      &_wrapper {
        display: flex;
        border-bottom: 1px dotted $light-gray;
      }
      &_buyer {
        width: 65%;
      }
      &_seller {
        width: 35%;
      }
      &_title {
        font-size: 12px;
        font-weight: 700;
        padding-bottom: 20px;
        border-bottom: 1px dotted $light-gray;
      }
      &_columns {
        display: flex;
      }
    }
    &_buttons {
      display: flex;
      padding-top: 40px;
      border-top: 1px dotted $light-gray;

      .btn-custom {
        &_main {
          height: 32px;
          padding: 5px;
        }
      }
    }
    &_signatures {
      &__title {
        font-size: 16px;
        font-weight: 700;
      }
      &__side {
        width: 50%;
        &.__min {
          width: 30%;
        }
      }
      &__input {
        width: 65%;
      }
      &__waiting-text {
        font-size: 12px;
        color: $orange;
        font-weight: 700;
        display: flex;
        line-height: 20px;
        svg {
          margin-right: 10px;
        }
      }
      &__contact {
        display: flex;
        flex-flow: column nowrap;
        font-size: 14px;
        span {
          font-weight: 700;
        }
        .__date {
          color: $mid-gray;
          font-size: 12px;
        }
      }
    }
    &_done-signatures {
      border-top: 1px dotted $light-gray;
      display: flex;
      flex-flow: column nowrap;
      font-size: 12px;
      &__title {
        color: $mid-gray
      }
      &__value {
        font-weight: 500;
      }
    }
  }
}
