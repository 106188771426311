.account-subscriptions {
  &_main {
    &__wrapper {
    }
  }
  &_block {
    &__wrapper {
      margin-bottom: 70px;
    }
    &__title {
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 40px;
    }
    &__total {
      text-align: right;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
  &_item {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px dotted $light-gray;
    }
    &__info {
    }
    &__actions {
      min-width: 200px;
      width: 20%;
      text-align: right;
    }
  }
  &_info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &__status {
      position: relative;
      margin-right: 15px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #f5f5f5;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
      border: 2px solid #fff;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url(../../../images/svg/check/check-white.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 15px;
        display: none;
      }
      &.__blue {
        background-color: $link;
        &:before {
          display: block;
        }
      }
      &.__red {
        background-color: $red;
      }
      &.__green {
        background-color: $green;
      }
    }
    &__title {
      margin-right: 40px;
      font-size: 16px;
      font-weight: 700;
      color: #000;
      &.__blue {
        color: $link;
      }
      &.__red {
        color: $red;
      }
      &.__green {
        color: $green;
      }
    }
    &__extra {
      display: inline-block;
      font-size: 16px;
      span {
        text-transform: uppercase;
      }
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
  &_sub-info {
    margin-top: 20px;
    margin-left: 55px;
    display: flex;
    flex-wrap: wrap;
    &__item {
      margin-right: 20px;
      position: relative;
      display: flex;
      span {
        font-size: 24px;
        line-height: 1;
        padding-right: 5px;
      }
    }
  }
  &_actions {
    &__btn {
      background-color: transparent;
      font-size: 12px;
      padding: 10px;
      border-radius: 3px;
      border: 1px solid $light-gray;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        background-color: #f5f5f5;
      }
      &.__danger {
        color: $red;
        border-color: $red;
      }
      &.__primary {
        color: $link;
        border-color: $link;
      }
    }
  }
}
