.modal-auth {
  button {
    margin: 42px 0 84px 0;
    @include for-size(md) {
      margin: 25px 0 84px 0;
    }
    @include for-size(xs) {
      width: 100%;
      padding: 10px;
      height: 51px;
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 40px;
    @include for-size(xs) {
      justify-content: space-around;
    }
  }

  &__form-code {
    .form {
      width: 60px;
      margin-right: 10px;
      @include for-size(xs) {
        width: 75px;
      }

      &:last-child {
        margin-right: 0;
      }

      input {
        width: 100%;
        padding-left: 0;
        text-align: center;
      }
    }
  }

  .modal-auth-code {
    .form {
      input {
        text-align: center;
      }
    }
  }

  .react-number-input {
    height: 60px;
    width: 285px;
    background: $white;
    border: none;
    font-size: 14px;
    line-height: 17px;
    border-radius: 3px;
    outline: none;
    display: flex;
    align-items: flex-end;
    @include for-size(xs) {
      width: 100%;
    }
    .form-control {
      @include for-size(xs) {
        width: 100%;
      }
    }
    .country-list {
      text-align: left;
      width: 285px;
    }
  }

  .react-number-input-search {
    height: 60px !important;
    border: none !important;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    &:focus {
      outline: none;
    }
  }

  .react-number-btn {
    border: none !important;
    background: transparent !important;
  }

  .selected-flag {
    background: transparent !important;
    padding-left: 17px !important;
    .arrow {
      display: none;
    }
  }
}
