.time-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @include for-size(md) {
    align-items: center;
  }

  p {
    font-size: 14px;
    margin: 0;
    @include for-size(md) {
      margin-bottom: 10px;
    }
  }

  img {
    margin-right: 12px;
    @include for-size(md) {
      width: 35px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  &__hours {
    min-width: 55px;
  }

  &__date {
    min-width: 108px;
  }
}
