.settings-account {
  &__wrapper {
    display: flex;
    h4 {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &_form {
    &__wrapper {
      flex: 0.5;
      margin-right: 100px;
      input {
        width: 400px;
        @include for-size(md) {
          width: 100%;
        }
      }
      .btn-custom_main.save-btn {
        margin-top: 30px;
      }
    }

    &__number {
      margin-bottom: 60px;
      label {
        font-weight: bold;
        font-size: 14px;
        margin-left: 20px;
        @include for-size(md) {
          margin-left: 0;
        }
      }
      p {
        font-size: 14px;
        margin-left: 20px;
        margin-bottom: 20px;
        @include for-size(md) {
          width: 90%;
          margin-left: 0;
        }
      }

      .form_number {
        &__wrapper {
          width: 400px;
          border: 1px solid $black;
          border-radius: 3px;
          display: flex;
          background-color: $off-white;
          @include for-size(md) {
            width: 100%;
          }
          input {
            border: none;
            width: 100%;
            height: 60px;
            outline: none;
            padding-left: 20px;
            font-size: 14px;
            background-color: transparent;
          }
          img {
            margin-right: 20px;
          }
        }
      }
    }
  }

  &_logout {
    &__wrapper {
      flex: 0.3;
    }
  }
}
