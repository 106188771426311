.settings-form {
  &__btn {
    width: 225px;
    margin: 0 auto 40px auto;
    display: block;
    cursor: pointer;
    &[disabled] {
      cursor: not-allowed;
    }
    @include for-size(md) {
      width: 100%;
      margin-bottom: 60px;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $green;
    background-color: $green;
  }
  .custom-switch {
    display: flex;
    margin: 70px 0 20px 0;
    justify-content: center;
    .custom-control-input {
      margin-right: 20px;
    }
    .custom-switch-check{
      margin-right: 15px;
    }
    a {
      color: $link;
    }
  }
}
