.discussion {
  width: 35%;
  border-left: 1px dotted $light-gray;
  padding-left: 40px;
  min-height: 600px;
  @include for-size(md) {
    border: none;
    padding-left: 0;
    width: 100%;
    min-height: 400px;
    margin-top: 50px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    @include for-size(md) {
      margin-bottom: 35px;
    }

    img {
      width: 40px;
      height: 40px;
      @include for-size(md) {
        width: 30px;
        height: 30px;
      }
    }

    p {
      display: flex;
      align-items: center;

      &:before {
        content: url("../../images/svg/chat-icon.svg");
        background-repeat: no-repeat;
        margin-right: 17px;
      }
    }
  }
}
