.admin {
  &__wrapper {
    position: relative;
    background-color: #efefef;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  &_body {
    &__wrapper {
      display: flex;
      flex-direction: column;
      padding-left: 200px;
      flex: 1 0 auto;
    }
  }
  &_content {
    &__wrapper {
      padding: 20px 5px;
      .content-box {
        margin: 0 15px 20px 15px;
        padding: 15px;
        background-color: #ffffff;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        .subtitle {
          font-weight: 700;
          margin: 0px;
        }
        .ant-table-wrapper
          .ant-table
          .ant-table-content
          .ant-table-thead
          .ant-table-cell {
          white-space: pre-line;
        }
      }
    }
    &__flex {
      display: flex;
    }
  }
}
