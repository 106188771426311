@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .members {
    .member {
      &_notification {
        &__wrapper {
        }
        &__item {
          flex-direction: column;
          justify-content: center;
        }
        &__desc {
          width: 100%;
          flex-direction: column;
          margin-bottom: 25px;
          margin-top: 10px;
          img {
            margin: 0 auto 5px;
          }
          p {
            strong {
              word-break: break-all;
            }
          }
        }
        &__actions {
          justify-content: center;
          button {
            margin: 0 10px 10px;
          }
        }
      }
    }
  }

  .settings-account {
    &__wrapper {
      flex-wrap: wrap;
    }
    &_form {
      &__wrapper {
        margin-right: 0;
        margin-bottom: 50px;
        flex: 1 1 100%;
      }
    }
    &_logout {
      &__wrapper {
        flex: 1 1 100%;
      }
    }
  }
}
