.decorative-search {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  @include for-size(md) {
    margin-bottom: 20px;
  }
  img {
    @include for-size(md) {
      width: 20px;
      height: 20px;
    }
  }
  h3 {
    font-size: 24px;
    text-transform: uppercase;
    margin-left: 22px;
    margin-bottom: 0;
    line-height: 130%;
    @include for-size(xs) {
      font-size: 16px;
    }
  }
}
