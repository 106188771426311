@import "current-messages";

.requests-to-us {
  background: $white;
  margin-bottom: 40px;
  border-radius: 3px;
  .request-to-us_header__wrapper {
    .view-header__above-title {
      color: $mid-gray;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  .content-block {
    &_header {
      margin-bottom: 0px;
      padding: 40px 45px;
      svg {
        margin: 4px 20px 0px 0px;
      }
    }
    &_wrapper {
      min-height: 50vh;
    }
    &_title {
      line-height: 31px;
    }
  }
  &_head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    &__icon {

      /* hiding for now til we have auto-selection */
      visibility: hidden;
      display: none;

      width: 50px;
      svg {
        margin-top: 3px;
      }
      &.__select {
        .ant-select {
          min-width: 55px;
          .ant-select-selector {
            background-color: transparent;
            border: 0;
            font-weight: 700;
            padding-left: 2px;
            &:after {
              display: none;
            }
            &:hover {
              background-color: #efefef;
            }
            .ant-select-selection-item {
              position: relative;
              padding-right: 30px;
              &:after {
                position: absolute;
                content: "";
                display: block;
                top: calc(50% + 2px);
                right: 3px;
                width: 10px;
                height: 10px;
                transform: translateY(-50%);
                border: 6px solid transparent;
                border-top: 6px solid $link;
                transition: 0.2s;
                visibility: inherit;
              }
            }
          }
          &.ant-select-open,
          &.ant-select-focused {
            .ant-select-selector {
              box-shadow: none !important;
            }
          }
          .ant-select-arrow {
            display: none;
          }
        }
      }
    }
    &__name {
      width: 25%;
    }
    &__column {
      width: 15%;
      &.__location {
        width: 20%;
      }
    }
    &__empty {
      width: 25%;
    }
  }
  &_body {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
    border-radius: 3px;
    transition: 0.3s;
    color: $black;
    cursor: pointer;
    &.bold {
      font-weight: 700;
    }
    &:hover {
      border-color: rgba($link, 0.3);
      color: $link;
      .icon-table {
        fill: $link;
      }
      .badge sup {
        background-color: $link;
      }
    }
    &:not(:last-child){
      border-bottom: 1px dotted $light-gray;
    }
    &__icon {
      width: 50px;

      /* hiding for now til we have auto-selection */
      visibility: hidden;
      display: none;
    }

    &__name {
      width: 25%;
    }
    &__column {
      width: 15%;
      &.__date {
        color: $discrete-text;
        font-size: 12px;
        line-height: 25px;
      }
      &.__location {
        width: 20%;
      }
    }
    &__empty {
      width: 25%;
      padding-left: 7%;
      font-size: 12px;
      &.green {
        color: $green;
      }
      &.red {
        color: $red;
      }
    }
  }
  .contract-tab {
    &__title {
      font-size: 16px;
      font-weight: 700;
    }
    &_documents {
      &_wrapper {
        display: flex;
        border-bottom: 1px dotted $light-gray;
      }
      &_buyer {
        width: 65%;
      }
      &_seller {
        width: 35%;
      }
      &_title {
        font-size: 12px;
        font-weight: 700;
        padding-bottom: 20px;
        border-bottom: 1px dotted $light-gray;
      }
      &_columns {
        display: flex;
      }
    }
    &_signatures {
      &__title {
        font-size: 16px;
        font-weight: 700;
      }
      &__side {
        width: 50%;
        &.__min {
          width: 30%;
        }
      }
      &__input {
        width: 65%;
      }
      &__waiting-text {
        font-size: 12px;
        color: $orange;
        font-weight: 700;
        display: flex;
        line-height: 20px;
        svg {
          margin-right: 10px;
        }
      }
      &__contact {
        display: flex;
        flex-flow: column nowrap;
        font-size: 14px;
        span {
          font-weight: 700;
        }
        .__date {
          color: $mid-gray;
          font-size: 12px;
        }
      }
    }
    &_done-signatures {
      border-top: 1px dotted $light-gray;
      display: flex;
      flex-flow: column nowrap;
      font-size: 12px;
      &__title {
        color: $mid-gray
      }
      &__value {
        font-weight: 500;
      }
    }
  }
}


.current-request{
  &_text{
    &.__wrapper{
      margin-bottom: 40px;
      p{
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  &_contacts{
    &.__wrapper{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    &.__title{
      text-transform: uppercase;
      margin-right: 40px;
      margin-bottom: 0;
    }
    &.__initials{
      display: inline-block;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      font-size: 8px;
      line-height: 20px;
      font-weight: 700;
      text-align: center;
      border-radius: 50%;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
      user-select: none;
      text-transform: uppercase;
    }
    &.__name{
      font-size: 14px;
      text-transform: capitalize;
      display: flex;
      margin-right: 20px;
    }
    &.__phone{
      display: flex;
      align-items: center;
      svg{
        margin-right: 10px;
        width: 15px;
      }
    }
  }
  &_docs{
    &.__wrapper{
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      padding-top: 40px;
      border-top: 1px dotted #a9a9a9;
    }
  }
}

.current-our-offer{
  &_info{
    &.__wrapper{
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
    }
    &.__item{
      margin-right: 80px;
      align-self: flex-start;
      h6{
        margin-bottom: 10px;
        color: $mid-gray;
        font-size: 10px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
      }
      h4{
        font-size: 24px;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1;
        b{
          color: $green;
        }
        span{
          font-size: 16px;
          margin-left: 5px;
          color: $mid-gray;
        }
      }
      p{
        margin-bottom: 0;
      }
    }
  }
  &_sub-info{
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px dotted #a9a9a9;
    &.__wrapper{
      display: flex;
      flex-wrap: wrap;
    }
  }
  &_message-text{
    &.__wrapper{
      width: 60%;
      padding-right: 40px;
      margin-right: 40px;
      border-right: 1px dotted #a9a9a9;
      min-width: 600px;
    }
    &.__title{
      margin-bottom: 20px;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1;
      svg, img{
        flex: 0 0 auto;
        width: 20px;
        margin-right: 10px;
      }
    }
  }
  &__documents{
    margin-top: 0;
  }
  &_edit{
    &.__wrapper{
      label{
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        padding-left: 20px;
        min-width: 180px;
      }
    }
    &.__field{
      width: 50%;
      padding: 0 15px 0 0;
      input{
        width: 100%;
        height: 60px;
        background: #ffffff;
        border: none;
        font-size: 14px;
        line-height: 17px;
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        outline: none;
        padding-left: 20px;
      }
    }
    &.__message{
      width: 100%;
      textarea{
        display: block;
        margin-bottom: 15px;
        padding: 10px 20px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: 120px;
        max-height: 200px;
        background: #ffffff;
        border: none;
        font-size: 14px;
        color: #626262;
        line-height: 17px;
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        outline: none;
      }
    }
    &.__actions{
      display: flex;
      justify-content: flex-end;
      button{
        margin-right: 10px;
      }
    }
  }
}
