.admin-drawer{
  &_header{
    &__wrapper{
      min-height: 40px;
      padding: 30px;
    }
    &__close{
      display: block;
    }
  }
  &_content{
    &__wrapper{
      padding-top: 80px;
    }
  }
  &_form{
    &__wrapper{

    }
  }
}