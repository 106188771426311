.our-offers {
  background: $white;
  border-radius: 3px;
  &-table-title {
    margin-top: 50px;
  }
  .empty-data {
    text-align: center;
    font-weight: 700;
  }
  .content-block {
    &_header {
      margin-bottom: 0px;
      padding: 40px 45px;
      svg {
        margin: 2px 20px 0px 0px;
        width: 40px;
        height: 36px;
      }
    }
    &_wrapper {
      min-height: 50vh;
      .not-bold h3{
        font-weight: 400;
      }
    }
    &_title {
      line-height: 31px;
    }
  }
  
  /* Setting column widths as common variables for this table */
  $requests-card-column-wide: 25%;
  $requests-card-column-narrow: 15%;
  $requests-card-column-last-filler: 15%;


  .requests-card {
    &_head {
      font-size: 12px !important;
      text-transform: uppercase;
      font-weight: bold;
      &__empty {
        &.__wrapper {
          flex-basis: $requests-card-column-last-filler;
          width: auto;
        }
      }
      &__name {
        &.__wrapper {
          flex-basis: $requests-card-column-wide;
          width: auto;
        }
      }
      &__column {
        &.__wrapper {
          flex-basis: $requests-card-column-narrow;
          width: auto;
        }
      }
    }
    &_body {  
      padding: 10px;
      &__column {
        &.__wrapper {
          flex-basis: $requests-card-column-narrow;
          width: auto;
          padding-top: 5px;
          font-size: 14px;
          line-height: 25px;
          align-items: flex-start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.date {
            font-size: 12px;
          }
        }
      }

      &__indicator {
        &.__wrapper {
          margin-top: 5px;
          flex-basis: $requests-card-column-last-filler;
          width: auto;
        }
      }
  
      &__name {
        &.__wrapper {
          font-size: 14px;
          display: flex;
          align-items: flex-start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 10px;
          flex-basis: $requests-card-column-wide;
          width: auto;
          
        }
        .gray-body {
          background-color: rgba(27, 162, 204, 0.05);
          padding: 5px 10px;
          border-radius: 3px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            position: relative;;
            top: -2px;
          }
          
        }
      }
    }
  }
}