.organization-card {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid transparent;
  align-items: center;
  &:hover {
    border: 1px solid $link;
    border-radius: 3px;
  }
  &_body {
    cursor: pointer;
  }
  &__left-side {
    display: flex;
    align-items: center;
  }
  &__right-side {
    font-weight: bold;
    font-size: 9px;
    i {
      color: gray;
    }
    img {
      margin-left: 40px;
    }
  }
  &__remove-icon {
    background: transparent;
    border: none;
    margin-left: 40px;
  }
  &__logo {
    margin-right: 20px;
    padding: 16px 10px;
    font-size: 14px;
    font-style: italic;
    border: 1px solid $light-gray;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    width: 90px;
    overflow: hidden;
    text-align: center;
  }
  &__about {
    display: flex;
    align-items: center;
    h2 {
      font-size: 16px;
      color: $link;
      margin-bottom: 0;
      margin-right: 10px;
      white-space: nowrap;
    }
    div {
      display: flex;
    }
    .btn-custom__delete {
      cursor: pointer;
    }
  }
  &__favorite {
    display: flex;
    align-items: center;
    button {
      background: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  &__btns {
    flex-wrap: wrap;
    width: 100%;
    button {
      font-size: 12px;
      padding: 5px 10px;
      height: 22px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

// HEAD AND BODY
.organization-card {
  &_head,
  &_body {
    &__indicator {
      &.__rating,
      &.__loading,
      &.__status,
      &.__sub-status {
        text-align: center;
        margin: 0 22px;
        display: flex;
        justify-content: center;
      }
      &.__rating {
        width: 130px;
      }
      &.__loading {
        width: 80px;
      }
      &.__status {
        width: 30px;
      }
      &.__sub-status {
        width: 26px;
      }
    }
  }

  &_head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 10px 20px 10px;
    border-bottom: 1px dotted $light-gray;
    &__name {
      &.__wrapper {
        width: 50%;
      }
      &.__select {
        .ant-select {
          min-width: 55px;
          .ant-select-selector {
            background-color: transparent;
            border: 0;
            font-weight: 700;
            &:after {
              display: none;
            }
            &:hover {
              background-color: #efefef;
            }
            .ant-select-selection-item {
              position: relative;
              padding-right: 30px;
              &:after {
                position: absolute;
                content: "";
                display: block;
                top: calc(50% + 2px);
                right: 3px;
                width: 10px;
                height: 10px;
                transform: translateY(-50%);
                border: 6px solid transparent;
                border-top: 6px solid $link;
                transition: 0.2s;
                visibility: inherit;
              }
            }
          }
          &.ant-select-open,
          &.ant-select-focused {
            .ant-select-selector {
              box-shadow: none !important;
            }
          }
          .ant-select-arrow {
            display: none;
          }
        }
      }
    }
    &__indicator {
      &.__wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
  &_body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 3px;
    transition: 0.3s;
    &:hover {
      border-color: rgba($link, 0.3);
    }


    &__name {
      &.__wrapper {
        display: flex;
        align-items: center;
        width: 50%;
      }

      &.__logo {
        width: 60px;
        min-width: 60px;
        height: 60px;
        margin-right: 20px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          border: 1px solid $light-gray;
          border-radius: 3px;
          color: #000;
        }
        img {
          width: 100%;
        }
      }

      &.__title {
        display: flex;
        align-items: center;
        margin-right: 25px;
        a {
          margin-right: 10px;
          font-weight: 700;
          font-size: 16px;
          color: $link;
        }
        img {
          width: 20px;
        }
      }

      &.__action {
        .btn-custom__delete {
          cursor: pointer;
          img {
            width: 20px;
          }
        }
      }
    }

    &__indicator {
      &.__wrapper {
        display: flex;
        align-items: center;
      }

      &.__rating {
        .rating-stars_wrapper {
          span {
            img {
              width: 18px;
              height: auto;
            }
          }
        }
      }

      &.__loading {
        font-weight: 700;
        font-size: 12px;
        color: #000;
        span {
          padding-left: 4px;
          color: $mid-gray;
        }
      }

      &.__status {
        .indicator_status {
          position: relative;
          width: 20px;
          height: 20px;
          border-radius: 0 50% 50% 50%;
          background-color: $light-gray;
          transform: rotate(-135deg);
          display: block;
          &.__1 {
            background-color: $red;
            transform: rotate(-20deg);
          }
          &.__2 {
            background-color: $orange;
            transform: rotate(20deg);
          }
          &.__3 {
            background-color: $link;
            transform: rotate(65deg);
          }
          &.__4 {
            background-color: $green;
            transform: rotate(110deg);
          }
          &_text {
            display: block;
            color: $white;
            font-size: 11px;
            font-weight: 700;
            &.__1 {
              margin-top: 1px;
              transform: rotate(20deg);
            }
            &.__2 {
              margin-top: 1px;
              transform: rotate(-20deg);
            }
            &.__3 {
              margin-left: -1px;
              margin-top: 1px;
              transform: rotate(-65deg);
            }
            &.__4 {
              margin-left: -2px;
              margin-top: 2px;
              transform: rotate(-110deg);
            }
          }
        }
      }

      &.__sub-status {
        width: 26px;
        .indicator_sub-status {
          position: relative;
          display: block;
          width: 20px;
          height: 20px;
          &:before {
            position: absolute;
            content: "";
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            background-position: center;
          }
          &.__1 {
            &:before {
              background-image: url(../../../images/svg/status/status-check.svg);
            }
          }
          &.__2 {
            &:before {
              background-image: url(../../../images/svg/status/status-cross.svg);
            }
          }
          &.__3 {
            &:before {
              background-image: url(../../../images/svg/status/status-question.svg);
            }
          }
        }
      }
    }
  }
}
