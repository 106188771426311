.articles {
  display: flex;
  margin-top: 40px;
  @include for-size(xs) {
    flex-direction: column;
  }
  span {
    color: $mid-gray;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 20px;
    display: block;
  }
  .time-item {
    @include for-size(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__left-side {
    width: 65%;
    padding-right: 40px;
    @include for-size(xs) {
      width: 100%;
      padding-right: 0;
    }
  }
  &__article-totals {
    width: 35%;
    border-left: 1px dotted $light-gray;
    padding-left: 40px;
    @include for-size(xs) {
      width: 100%;
      padding-left: 0;
      padding-top: 20px;
      border-left: none;
      border-top: 1px dotted $light-gray;
    }
  }
  &__article {
    display: flex;
    margin-bottom: 10px;
    p {
      font-size: 14px;
      margin-right: 33px;
    }
    strong {
      font-size: 14px;
    }
  }
}
