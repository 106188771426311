.my-organisation {
  display: flex;
  width: 55%;
  @include for-size(l) {
    width: 65%;
  }
  @include for-size(md) {
    width: 100%;
  }
  @include for-size(xs) {
    display: block;
  }
  .form {
    input {
      width: 100%;
      font-size: 14px;
    }
  }
  &__block {
    width: 50%;
    @include for-size(xs) {
      width: 100%;
    }
  }
  &__first-block {
    @include for-size(xs) {
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid #afafaf;
    }
    .form {
      margin-right: 40px;
      @include for-size(xs) {
        margin-right: 0;
      }
    }
  }
  &__textarea {
    display: flex;
    flex-direction: column;
    label {
      font-size: 14px;
      line-height: 130%;
      display: block;
      margin: 20px 0 10px 20px;
    }
    textarea {
      padding-top: 20px;
      margin-right: 40px;
      resize: none;
      min-height: 94px;
      @include for-size(xs) {
        margin-right: 0;
      }
    }
  }
}
