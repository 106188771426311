.invite-page{
  &__wrapper{
    background-color: #efefef;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &__title{
    font-size: 2.75rem;
    margin: 5vh 0 30px;
    font-weight: 700;
    text-align: center;
  }

  &__content{
    flex: 1 1 auto;
    margin-top: 5vh;
    margin-bottom: 50px;
  }

  &_phone, &_code, &_info, &_choice {
    &.__form{
      margin: 0 auto;
      max-width: 430px;
      background-color: #fff;
      padding: 40px 20px;
      border-radius: 5px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }
    &.__actions{
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 20px;
      padding: 0 40px;
      button {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }

      .button-red {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  &_phone{
    &.__form{
      .react-tel-input.react-number-input {
        .react-number-input-search.form-control {
          width: 100%;
          height: 60px;
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
          border: transparent;
        }
        .react-number-btn {
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
          background-color: #ffffff;
          border: transparent;
          .selected-flag {
            background-color: transparent;
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  &_code{
    &.__fields{
      display: flex;
      align-items: center;
      justify-content: center;
      .form{
        width: 60px;
        margin-right: 10px;
      }
      .input{
        height: 60px;
        background: #ffffff;
        border: none;
        font-size: 14px;
        line-height: 17px;
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        outline: none;
        width: 100%;
      }
    }
  }

  &_info{
    &.__switch{
      display: flex;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 50px;
      p{
        padding-left: 15px;
      }
    }
  }

  &_choice{
    &.__form{
      h3{
        text-align: center;
      }
    }
    &.__actions{

      button{
        margin: 0 10px;
        min-width: 150px;
        padding: 20px;
        text-transform: uppercase;
      }
    }
  }
}