.custom-tooltip {
  & > .ant-tooltip-content > .ant-tooltip-inner {
    white-space: break-spaces;
    border-radius: 3px;
  }
  &.center-text {
    .ant-tooltip-content > .ant-tooltip-inner {
      text-align: center;
    }
  }
  &.no-wrap-text {
    max-width: unset;
    .ant-tooltip-content > .ant-tooltip-inner {
      white-space: nowrap;
    }
  }
}
