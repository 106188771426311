@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .presentation-card {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    &__content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .contact-person {
        &_wrapper {
          justify-content: space-between;
        }
        &_item {
          width: 100%;
          min-width: auto;
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
          padding: 15px;
          border: 1px dotted $light-gray;
          border-radius: 3px;
        }
      }

      .insurance-card {
        width: 100%;
        min-width: auto;
        flex-wrap: wrap;
        flex-direction: column;
        margin-bottom: 20px;
        padding: 15px;
        border: 1px dotted $light-gray;
        border-radius: 3px;
        p {
          margin-right: 0;
          max-width: 100%;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        &__download {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .edit-our-services {
    &_wrapper {
    }
    &_item {
      margin-right: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .custom-amount-table {
    &_head {
      display: none;
    }
    &_body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &__item {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: auto;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        padding: 15px;
        border: 1px dotted $light-gray;
        border-radius: 3px;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
      &__value {
        width: 100%;
        margin-right: 0;
        &:before {
          position: relative;
          content: attr(data-title);
          display: inline-block;
          margin-right: 10px;
        }
        &.__big {
          width: 100%;
        }
      }
    }
  }

  .custom-table {
    &_head {
      display: none;
    }
    &_body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &__item {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: auto;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        padding: 15px;
        border: 1px dotted $light-gray;
        border-radius: 3px;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
      &__value {
        width: 100%;
        margin-right: 0;
        &:before {
          position: relative;
          content: attr(data-title);
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
  }

  .rating-observer {
    &_head {
      flex-wrap: wrap;
      margin-bottom: 30px;
      &__collapse {
        display: block;
        width: 100%;
        .rating-observer_item__wrapper {
          margin-bottom: 20px;
        }
      }
      &__btn {
        width: 100%;
        max-width: 260px;
        border: 1px solid #6b6b6b;
        border-radius: 3px;
        margin: 0 auto;
        &:before {
          right: 12px;
        }
      }
    }
  }
}
