.admin_sidebar {
  &__wrapper {
    padding: 20px 10px;
    min-height: 100vh;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    background-color: #2c3e4e;
    overflow-y: auto;
  }
  &__brand {
    display: block;
    position: relative;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid grey;
    a {
      font-size: 1.75rem;
      font-weight: 700;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
  &__sub-title {
    color: #9a9a9a;
    font-size: 1rem;
    margin-bottom: 5px;
  }
  &__list {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    a {
      color: #fff;
      width: 100%;
      padding: 5px 10px;
      transition: 0.2s;
      border-radius: 3px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        color: #fff;
      }
      svg {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
}
