.joining-organization {
  p,
  span {
    font-size: 16px;
    @include for-size(md) {
      font-size: 14px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    img {
      width: 155px;
      height: 30px;
      @include for-size(md) {
        width: 105px;
        margin-bottom: 10px;
      }
    }
    @include for-size(md) {
      padding: 20px;
      flex-direction: column;
    }
  }
  &__body {
    width: 60%;
    margin: 0 auto;
    @include for-size(md) {
      width: 100%;
      margin: 0;
      padding: 20px;
    }
  }
  &__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
    margin: 125px 0 84px 0;
    display: flex;
    align-items: center;
    @include for-size(md) {
      font-size: 16px;
      margin: 45px 0 44px 0;
    }
    img {
      margin-right: 20px;
      @include for-size(md) {
        width: 25px;
      }
    }
  }
  &__desc {
    margin-bottom: 90px;
    @include for-size(md) {
      margin-bottom: 70px;
    }
  }
  &__btn {
    height: 61px;
    padding: 20px 80px;
    @include for-size(md) {
      height: 51px;
      padding: 10px 0;
      font-size: 12px;
    }
  }
}
