.btn {
  height: 61px;
  padding: 20px 80px;
  text-align: center;
  border-radius: 3px;
  border: none;
  font-size: 16px;
  line-height: 130%;
  outline: none;
  background-color: $link;
  cursor: pointer;
  color: #fff;
  &[disabled] {
    cursor: not-allowed;
    border: 1px solid transparent;
  }
}

button{
  .preloader{
    height: 100%;
  }
}

.btn-custom {
  &_main {
    position: relative;
    cursor: pointer;
    align-items: center;

    &.__full-bg {
      background-color: $link !important;
      color: #fff !important;
      &[disabled] {
        background-color: #D8D8D8 !important;
        color: #A9A9A9 !important;
      }
    }

    &.__center-loader {
      justify-content: center;
    }

    &.__small {
      height: 32px;
    }
    &.__large {
      height: 61px !important;
      padding: 20px 80px !important;
    }

    &[disabled] {
      cursor: not-allowed;
      border: 1px solid transparent !important;
    }
    &.__image-right{
      img, svg {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }
  &_showSidebar {
    margin-right: 30px;
    position: relative;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    display: none;
    &:hover {
      span {
        width: 28px;
      }
      &:before,
      &:after {
        width: 28px;
      }
    }
    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 26px;
      height: 2px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #000;
      transition: 0.2s;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
    span {
      position: relative;
      display: inline-block;
      width: 22px;
      height: 2px;
      background-color: #000;
      transition: 0.2s;
    }
  }
  &_close-modal {
    display: none;
    position: relative;
    background-color: transparent;
    width: 30px;
    height: 30px;
    padding: 0;
    box-shadow: none;
    border: 0;
    cursor: pointer;
    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 22px;
      height: 2px;
      background-color: #000;
      top: 50%;
      left: 50%;
      transition: 0.4s;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    &._active {
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
        transition-delay: 0.3s;
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
        transition-delay: 0.3s;
      }
    }
  }

  &__delete {
    border: 0;
    box-shadow: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    &[disabled]{
      opacity: 0.2
    }
  }

  &__edit,
  &__cross {
    border: none;
    background: transparent;
    cursor: pointer;
  }
}

.btn-transparent {
  height: 41px;
  background: transparent;
  border: 1px solid $link;
  color: $link;
  border-radius: 3px;
  padding: 10px;
  font-size: 16px;
  line-height: 130%;
  outline: none;
  text-align: center;
  display: flex;
  img, svg {
    margin-right: 10px;
  }


  & .ant-spin {
    margin: 0 20px;
  }
  &.__danger {
    border-color: #ba2e2e;
    color: #ba2e2e;
  }
}

.remove-btn {
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  color: $red;
  p {
    margin-bottom: 0;
    margin-left: 10px;
    color: $black;
  }
}

.make-btn {
  margin-right: 37px;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  .ant-select-selection-item {
    color: $orange;
  }
  .ant-select-selector {
    box-shadow: none !important;
    border: none !important;
    background: transparent;
    padding: 0 !important;
    display: flex;
    align-items: center;
    &:before {
      content: "";
      background: url("../../../images/svg/icon-arrow-bottom.svg") no-repeat;
      width: 20px;
      height: 20px;
      background-size: cover;
      margin-right: 7px;
    }
  }
}

.add-btn {
  display: flex;
  border-bottom: 1px dotted $light-gray;
  align-items: center;
  width: 389px;
  padding: 20px 0;
  @include for-size(sm) {
    width: 100%;
  }
  p {
    line-height: 100%;
    margin-bottom: 0;
    margin-left: 16px;
  }
  button {
    background: url("../../../images/svg/add.svg") no-repeat;
    width: 40px;
    height: 40px;
    border: none;
  }
}

.btn {
  &-red, &-green, &-blue {
    border-width: 1px;
    border-style: solid;
    &.__full {
      color: #fff;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
    }
  }
  &-red {
    border-color: $red;
    color: $red;
    &.__full {
      background-color: $red;
    }
  }
  &-green {
    border-color: $green;
    color: $green;
    &.__full {
      background-color: $green;
    }
  }
  &-blue {
    border-color: $link;
    color: $link;
    &.__full {
      background-color: $link;
    }
  }
}

.first-login-btn {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  padding: 20px 40px;
  color: #fff !important;
  border: 1px solid #1BA2CC;
  box-sizing: border-box;
  border-radius: 3px;
  background: #1BA2CC;
  position: absolute;
  top: 28vh;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  color: #000;
  white-space: nowrap;
  
  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
    padding: 22px 42px;

 }
}

.handybeaver {
  position: absolute;
  height: 50vh;
  width: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.btn-loader {
  .ant-spin-dot-item {
    background-color: $white;
  }
}

.button-red {
  border-radius: 3px;
  background: indianred;
  color: $white;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
}
