.alert-error {
  .ant-alert-with-description {
    z-index: 2;
    position: fixed;
  }
}

.ant-modal-content {
  border-radius: 3px !important;
}
