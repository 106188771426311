.admin-translation{
  &_head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__left, &__right, &__center{
      display: flex;
      button{
        margin: 0 5px;
        &.__upload{
          img{
            transform: rotate(180deg);
          }
        }
      }
      input{
        &:focus{
          box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.5);
        }
      }
    }
    &__left{
      width: 25%;
    }
    &__center{
      width: 50%;
      justify-content: center;
      input{
        width: 300px;
        height: 52px;
      }
    }
    &__right{
      width: 25%;
      justify-content: flex-end;
    }
  }
  &_item__wrapper{
    &.__header{
      &:hover{
        background-color: transparent;
      }
      div{
        font-weight: 700;
      }
      .admin-translation_item__action{
        text-align: center;
      }
    }
    &.__footer{
      border: 0;
      margin-top: 30px;
      &:hover{
        background-color: transparent;
      }
    }
  }
  &_item{
    &__wrapper{
      padding: 15px 0;
      border-bottom: 1px solid lightgrey;
      background-color: #fff;
      transition: .1s;
      overflow: hidden;
      &:hover{
        background-color: rgba(#000, 0.02);
      }
    }
    &__inner-wrapper{
      display: flex;
      align-items: flex-start;
      max-height: 220px;
      overflow: hidden;
    }
    &__toggle, &__text, &__action{
      margin: 0 10px;
      span{
        &:hover{
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    &__text{
      flex: 1 1 auto;
      width: 20%;
      min-width: 20%;
      word-break: break-word;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      .form-field_wrapper{
        margin-bottom: 0;
      }
      .label{
        margin: 0;
        line-height: 1.5715;
        text-transform: capitalize;
      }
      input{
        padding: 12px 15px;
        text-transform: uppercase;
        &:focus{
          border-color: transparent;
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
        }
      }
      .trans__field{
        margin-bottom: 5px;
        width: 100%;
        min-width: 100%;
        min-height: 60px;
        max-height: 310px;
        overflow-y: auto;
        padding: 14px 20px;
        background: #ffffff;
        border: none;
        font-size: 14px;
        line-height: 17px;
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        outline: none;
        transition: background-color .3s, opacity .3s, color .3s;
        &[disabled]{
          color: rgba(0, 0, 0, 0.25);
          background-color: #f5f5f5;
          cursor: not-allowed;
          opacity: 1;
          overflow: hidden;
        }
      }
    }
    &__action{
      width: 10%;
      min-width: 140px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      button{
        &:nth-child(1){
          margin-top: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    &__inner-action{
      display: flex;
      .admin-translation_item__btn{
        width: calc(50% - 10px);
      }
    }
    &__btn{
      display: flex;
      justify-content: center;
      height: auto;
      min-height: auto;
      margin: 5px;
      padding: 3px 5px;
      font-size: 14px;
      background: #fff;
      transition: .2s;
      &[disabled]{
        border-color: rgb(169, 169, 169) !important;
      }
      &:hover{
        background: rgba(#000, 0.05);
      }
      &.__save{
        border-color: #6bc069;
        color: #6bc069;
        &:hover{
          background-color: #6bc069;
          color: #fff;
        }
      }
      &.__delete{
        border-color: #ba342e;
        color: #ba342e;
        &:hover{
          background-color: #ba342e;
          color: #fff;
        }
      }
      &.__cancel{
        &:hover{
          background-color: #1ba2cc;
          color: #fff;
        }
      }
    }
  }
}


// **** Edit && New Translation **** //
// **** Edit && New Translation **** //
// **** Edit && New Translation **** //
.admin-translation_item__wrapper.__edit, .admin-translation_item__wrapper.__new {
  background-color: rgba(44, 62, 78, 0.2);
  border-bottom-color: #efefef;
  .admin-translation_item{
    &__inner-wrapper{
      max-height: 350px;
    }
    &__text{
      text-decoration: none;
      input, textarea{
        &:focus{
          box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}