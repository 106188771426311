.presentation-card {
  margin-bottom: 60px;
  h4 {
    font-size: 16px;
    text-transform: uppercase;
  }
  &.__contact-person {
    margin-bottom: 30px;
  }
  &__title {
    display: flex;
    align-items: center;
    h4 {
      margin-bottom: 0;
      font-weight: 700;
      span {
        margin-left: 5px;
      }
    }
    img {
      margin-right: 10px;
      max-width: 21px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  &__select.ant-select{
    margin-left: 15px;
  }
  &__edit-btn {
    border: none;
    background: transparent;
    cursor: pointer;
  }
}

.insurance-card {
  
  font-size: 12px;
  align-items: center;


  &:nth-of-type(odd) {
    background: $off-white;
  }
  display: flex;
  &__first-parameter {
    flex-basis: 20%;
    font-weight: 600;

  }
  &__second-parameter {
    flex-basis: 15%;
  }
  &__download-btn-wrapper {
    margin-left: 10px;
    padding: 0 5px 5px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;
  }
  &__download {
    margin: 6px 0 1px 0;
    padding: 5px 10px 3px 5px;
    background: transparent;
    border-radius: 3px;
    border-color: rgba(27, 162, 204, 0.1) !important;
    &:hover {
      border-color: rgba(27, 162, 204, 0.3) !important;
    }
    border: none;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    color: $link;
    cursor: pointer;
    img {
      margin-right: 6px;
    }
  }

  p {
    margin: 0;
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include for-size(xl) {
      margin-right: 20px;
    }
    span {
      color: $mid-gray;
      margin-right: 8px;
    }
  }

}

.edit-our-services {
  &_wrapper {
    display: flex;
    flex-wrap: wrap;
    &._main-filters{
      .edit-our-services_item{
        width: auto;
      }
      .blob-title {
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      .blob-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        .edit-our-services_item.__min {
          margin-right: 0px;
          .edit-our-services_selected__item {
            margin-right: 0px;
          }
        }
      }
    }

      /* Shamefully hiding consultant, material and real estate using css */ 
      .materialsupplier-filters, 
      .consultant-filters, 
      .realestate-filters {
        display: none; visibility: hidden;
      }
  }
  &_item {
    margin-right: 30px;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    p{
      width: 100%;
    }
    &__inner-wrapper{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
  }
  &_title {
    margin-bottom: 15px;
    font-size: 14px;
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
  }
  &_sub-title{
    margin-bottom: 20px;
    font-size: 12px;
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
  }
  &_selected {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 10px 15px;
      color: $discrete-text;
      font-size: 16px;
      line-height: 1;
      border: 1px solid $discrete-text;
      border-radius: 50px;
    }
  }
}

.edit-insurance_wrapper {
  .editBlock_item::before {
    width: 90%;
  }
  .editBlock {
    &_field__item,
    &_select__item {
      margin-right: 15px;
    }
  }
  .editBlock_flex__wrap {
    justify-content: space-between;
    .editBlock_date-pecker__item {
      width: 130px;
      .editBlock_date-pecker__field.ant-picker {
        min-width: 130px !important;
        width: 130px;
      }
    }
    .editBlock_radio {
      &__title {
        padding-left: 0px;
        margin-right: 10px;
      }
      &__wrapper {
        padding-top: 20px;
        flex-flow: row nowrap;
      }
      &__switch {
        display: flex;
        flex-flow: row wrap;
      }
      &__item {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-right: 10px;
        label {
          font-size: 12px;
        }
      }
    }
    .editBlock_upload__item {
      flex-flow: row nowrap;
      padding-top: 10px;
      margin-right: 0px;
      align-items: baseline;
      .editBlock_upload__link {
        padding-bottom: 0px;
        margin-right: 10px;
      }
      .editBlock_upload__links {
        .editBlock_btn__clean {
          padding-top: 0px;
        }
      }
      span div {
        margin-top: 15px;
      }
    }
  }
}
