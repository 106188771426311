.requests-card {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid transparent;
  align-items: center;
  &:hover {
    border: 1px solid $link;
    border-radius: 3px;
  }
  &_body {
    cursor: pointer;
  }
  &__left-side {
    display: flex;
    align-items: center;
  }
  &__right-side {
    font-weight: bold;
    font-size: 9px;
    i {
      color: gray;
    }
    img {
      margin-left: 40px;
    }
  }
  &__remove-icon {
    background: transparent;
    border: none;
    margin-left: 40px;
  }
  &__logo {
    margin-right: 20px;
    padding: 16px 10px;
    font-size: 14px;
    font-style: italic;
    border: 1px solid $light-gray;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    width: 90px;
    overflow: hidden;
    text-align: center;
  }
  &__about {
    display: flex;
    align-items: center;
    h2 {
      font-size: 16px;
      color: $link;
      margin-bottom: 0;
      margin-right: 10px;
      white-space: nowrap;
    }
    div {
      display: flex;
    }
    .btn-custom__delete {
      cursor: pointer;
    }
  }
  &__favorite {
    display: flex;
    align-items: center;
    button {
      background: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  &__btns {
    flex-wrap: wrap;
    width: 100%;
    button {
      font-size: 12px;
      padding: 5px 10px;
      height: 22px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

// HEAD AND BODY
.requests-card {
  &_head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px 10px 0px 0px;
    margin-bottom: 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    &__name {
      &.__wrapper {
        width: 18%;
      }
      &.__select {
        .ant-select {
          min-width: 55px;
          .ant-select-selector {
            background-color: transparent;
            border: 0;
            font-weight: 700;
            padding-left: 2px;
            &:after {
              display: none;
            }
            &:hover {
              background-color: #efefef;
            }
            .ant-select-selection-item {
              position: relative;
              padding-right: 30px;
              &:after {
                position: absolute;
                content: "";
                display: block;
                top: calc(50% + 2px);
                right: 3px;
                width: 10px;
                height: 10px;
                transform: translateY(-50%);
                border: 6px solid transparent;
                border-top: 6px solid $link;
                transition: 0.2s;
                visibility: inherit;
              }
            }
          }
          &.ant-select-open,
          &.ant-select-focused {
            .ant-select-selector {
              box-shadow: none !important;
            }
          }
          .ant-select-arrow {
            display: none;
          }
        }
      }
    }
    &__indicator {
      &.__wrapper {
        display: flex;
        align-items: center;
      }
    }
    &__column {
      &.__wrapper {
        width: 18%;
      }
    }
  }
  &_body {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    margin-left: -10px;
    border: 1px solid transparent;
    border-radius: 3px;
    transition: 0.3s;
    color: $black;
    &:hover {
      border-color: rgba($link, 0.3);
      color: $link;
      .icon-table, .icon-table svg {
        fill: $link !important;
      }
      .badge sup {
        background-color: $link;
      }
    }

    &__column {
      &.__wrapper {
        width: 18%;
        font-size: 16px;
        line-height: 22px;
      }
      &.title {
        font-size: 14px;
      }
      &.date {
        font-size: 12px;
      }
    }

    &__name {
      &.__wrapper {
        display: flex;
        align-items: center;
        width: 18%;
      }
    }

    &__indicator {
      &.__wrapper {
        width: 46%;
        display: flex;
        justify-content: flex-end;
      }
      .table-badge {
        margin: 0px 10px 0px 10px !important;
      }
      .icon-table {
        margin-top: 2px;
      }
    }

    &__icon {
      &.__wrapper {
        flex-shrink: 0;
        width: 62px;
        img {
          margin-top: -5px;
        }
      }
    }

    &__recipient-name {
      &.__wrapper {
        display: flex;
        align-items: center;
        width: 55%;
      }
    }

    .organization-card_body__indicator.__sub-status {
      width: 15%;
      margin: 0px;
    }
  }
}
