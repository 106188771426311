// $font-family-sans-serif: "Nunito", sans-serif;

// Colors

$black: #000000;
$link: #1ba2cc;
$mid-gray: #a9a9a9;
$white: #ffffff;
$dimmed-opaque-white: rgba(255, 255, 255, 0.6);
$light-gray: #d8d8d8;
$off-white: #fafafa;
$discrete-text: #626262;
$red: #ba2e2e;
$green: #6bc069;
$orange: #c17e2e;
$purple: #ac4dc4;
$icon-gradient: linear-gradient(180deg, #1ba2cc 0%, #6bc069 100%);
$link-opaque-bg: rgba(27, 162, 204, 0.05);
$link-hover: rgba(27, 162, 204, 0.1);
$search-border: #e2e2e2;
