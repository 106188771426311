.invite-suppliers {
  &_wrapper {
    .info-chunk {
      margin-bottom: 40px;
    }
    .custom-title {
      text-transform: uppercase;
    }
    .btn-custom_wrap {
      display: flex;
      justify-content: space-between;
    }
  }
  &_form {
    margin-bottom: 80px;
    max-width: 700px;
    &__item {
      display: flex;
      position: relative;
      margin-bottom: 20px;
      .form {
        width: 100%;
        margin-right: 20px;
        .input {
          width: 100%;
        }
      }
    }
  }
  &_btn__clean {
    position: absolute;
    left: calc(100% + 20px);
    top: 50%;
    transform: translateY(-50%);
  }
  &__btn-add {
    margin-bottom: 15px;
  }
  &__btn-send {
    margin-bottom: 15px;
    margin-right: 20px;
  }
  &_invites {
    margin-bottom: 40px;
    max-width: 700px;
    width: 100%;
    .ant-table {
      background-color: transparent;
      &-row {
        &:hover {
          .ant-table-cell {
            background-color: transparent;
          }
        }
      }
      &-cell {
        border-bottom: 0;
        background-color: transparent;
        padding: 10px 10px 10px 0;

        &:last-child {
          color: $mid-gray;
        }
      }
    }
  }
}
