.multiselect-custom {
  &_wrapper {
    margin-right: 30px;
  }

  &_inner-wrapper{
    display: flex;
  }

  &_btn {
    &.__wrapper{
      position: relative;
      .btn-custom_main{
        border-radius: 50px;
        white-space: nowrap;
      }
    }
  }

  &_title{
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #000;
  }

  &_select {
    position: absolute;
    top: 42px;
    z-index: 99;
    .dropdown {
      &-container {
        border: 0 !important;
        box-shadow: none !important;
      }
      &-heading {
        width: auto;
        display: none;
        .clear-selected-button {
          display: none;
        }
      }
      &-content {
        margin-top: 5px;
        padding-top: 0;
        width: auto;
        max-width: 300px;
        min-width: 260px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        border: 0;
        z-index: 20;
        animation: fadeIn ease .2s;
        .panel-content{
          box-shadow: none;
        }
        .select-item {
          padding: 5px 12px;
          &.selected {
            background-color: #e6f7fe !important;
            font-weight: 600
          }
          .item-renderer {
            position: relative;
            padding-right: 20px;
            &.disabled{
              cursor: default;
            }
            &:before {
              position: absolute;
              content: "";
              top: 50%;
              right: 5px;
            }
            span{
              padding-left: 0;
            }
            input[type="checkbox"] {
              display: none;
            }
          }
        }
      }
    }
  }

  &_list {
    &.__wrapper{
      width: 100%;
      margin-left: 15px;
    }
    &.__inner-wrapper{
      display: flex;
      flex-wrap: wrap;
    }
  }

  &_item {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #1BA2CC;
    border-radius: 50px;
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
      font-size: 16px;
      line-height: 1;
      color: #ffffff;
    }

    .btn-custom__delete {
      height: 20px;
      padding: 0;
      svg {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}
