.organization-input {
  p {
    margin: 215px 0 54px 0;
    font-size: 16px;
  }
  input {
    margin-bottom: 68px;
    width: 400px;
  }
  button {
    margin-bottom: 150px;
    width: 255px;
  }
}
