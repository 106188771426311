.modal-ent {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  overflow: auto;
  &__wrapper {
    width: 60%;
    margin: 60px auto;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    background: $white;
    @include for-size(l) {
      width: 75%;
    }
    @include for-size(md) {
      width: 95%;
      margin: 25px auto;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    background: $off-white;
    padding: 40px;
    @include for-size(md) {
      padding: 30px 20px;
    }
  }
  &__close-modal {
    border: none;
    background: transparent;
    cursor: pointer;
  }
  &__content {
    text-align: center;
    @include for-size(md) {
      padding: 0 20px 40px 20px;
    }
    h2 {
      font-weight: 900;
      font-size: 50px;
      line-height: 130%;
      margin: 39px 0 30px 0;
      @include for-size(md) {
        font-size: 23px;
        margin: 20px 0;
      }
    }
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
