#root {
  min-height: 100vh;
}

.main-content {
  &_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    background: $off-white;
    .layout-content_wrapper {
      display: flex;
      flex: 1 0 auto;
      padding-right: 40px;
      padding-left: 30px;
    }
    .layout-content_inner-wrap {
      width: 100%;
      padding-left: 30px;
    }
    .footer {
      flex: 0 0 auto;
    }
  }
  &_inner__wrapper {
    display: flex;
    flex: 1 0 auto;
  }
}

.t_uppercase {
  text-transform: uppercase;
}

.__bold {
  font-weight: 700;
}
.__normal {
  font-weight: 400;
}
.__nonecase {
  text-transform: none;
}
.__strikethrough {
  text-decoration: line-through;
}
.__italic {
  font-style: italic;
}
.__text-right {
  text-align: right;
}

.db {
  display: block;
}

.mla {
  margin-left: auto !important;
}
.mra {
  margin-right: auto !important;
}
.maa {
  margin: auto;
}
.br-spaces {
  white-space: break-spaces;
}

.error-page_title {
  text-align: center;
  font-size: 3rem;
  padding: 3em;
}

.df {
  @include flex();
}
.df-row {
  @include flex();
  flex-flow: row nowrap;
}
.df-column {
  @include flex();
  flex-flow: column nowrap;
}
.df-row-center {
  @include flex();
  flex-flow: row nowrap;
  align-items: center;
}
.df-column-center {
  @include flex();
  flex-flow: column nowrap;
  align-items: center;
}
.df-column-end {
  @include flex();
  flex-flow: column nowrap;
  align-items: flex-end;
}
.df-row-jcc-center {
  @include flex();
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.df-row-jsb {
  @include flex();
  flex-flow: row nowrap;
  justify-content: space-between;
}
.df-row-jsb-center {
  @include flex();
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.df-row-jce-center {
  @include flex();
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}
.df-column-jcc-center {
  @include flex();
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.df-column-jce-center {
  @include flex();
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
}

.cpointer {
  cursor: pointer;
}

.blue,
.link {
  color: $link;
}
.green {
  color: $green;
}
.red {
  color: $red;
}
.purple {
  color: $purple;
}
.orange {
  color: $orange;
}
.light-gray {
  color: $light-gray;
}
.mid-gray {
  color: $mid-gray;
}
.discrete-text {
  color: $discrete-text;
}
.black {
  color: $black;
}
