@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .invite-suppliers {
    &_wrapper {
    }
    &_form {
      &__item {
        flex-wrap: wrap;
        .form {
          margin-right: 0;
          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }
        label {
          margin-top: 0;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
    &_invites {
      .ant-table {
        &-row {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
        }
        &-cell {
          padding: 0 5px;
        }
      }
    }
  }
}
