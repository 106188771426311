.preloader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader500 {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader100 {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
