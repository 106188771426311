.subscription-orders-table {
  tbody.ant-table-tbody > tr > td {
    word-break: break-all;
  }
  .organization-column {
    width: 20%;
  }
  .current-subscriptions-column {
    width: 25%;
  }
}
