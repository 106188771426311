.hd-content-block_header{
  &.__wrapper{
    padding: 40px;
    background-color: #fff;
    border-radius: 3px 3px 0 0;
  }
  &.__inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child){
      margin-bottom: 20px;
    }
  }
  &.__title{
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 0;
  }
  &.__address{
    display: flex;
    span{
      display: flex;
      align-items: center;
      padding: 5px 10px;
      font-size: 16px;
      color: #1BA2CC;
      border-radius: 3px;
      &:not(:last-child){
        margin-right: 20px;
      }
      &.fill{
        background: rgba(27, 162, 204, 0.05);
      }
      svg{
        margin-right: 10px;
      }
    }
  }
  &.__date{
    font-size: 12px;
    text-align: right;
    span{
      display: inline-block;
      margin-right: 5px;
      color: #A9A9A9;
      text-transform: uppercase;
      &.__closed-offer {
        color: $red;
      }
    }
  }
  &.__interest-status{
    display: flex;
    justify-content: flex-end;
    .btn-custom {
      &_main{
        margin-left: 15px;
        height: 32px;
        font-size: 12px;
        padding: 5px;
      }
    }
  }
}
