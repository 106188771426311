.our-blacklist {
  .content-block_header {
    svg {
      width: 38px;
      height: 38px;
    }
  }
  .info-chunk {
    margin-top: 40px;
    margin-left: 10px;
  }
}
