.finance-section{
  &_wrapper{
    .annual-report {
      &_wrap {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
      &_title {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 700;
        color: $discrete-text;
      }
      &_item {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &__title {
          width: 180px;
          font-size: 14px;
          color: $discrete-text;
        }
        &__value {
          font-size: 14px;
          color: #000000;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
  }
  &_sub-title{
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    color: $discrete-text;
  }
  &_half-block{
    max-width: 560px;
    padding-right: 40px;
    border-right: 1px dotted #A9A9A9;
  }
}

.custom-table {
  &_head__title,
  &_head__value,
  &_body__title,
  &_body__value {
    width: 180px;
    margin-right: 20px;
    font-size: 14px;
    color: $discrete-text;
  }
  &_head {
    display: flex;
    &__title,
    &__value {
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
  &_body {
    &__item {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &.__bold {
        div {
          font-weight: 700;
        }
      }
    }
  }
}

.custom-amount-table {
  &_wrapper{
    margin-bottom: 30px;
  }

  &_head__title,
  &_head__value,
  &_body__title,
  &_body__value {
    min-width: 180px;
    margin-right: 20px;
    font-size: 14px;
    color: $discrete-text;
  }
  &_head {
    display: flex;
    &__title,
    &__value {
      font-weight: 700;
      margin-bottom: 10px;
      &.__big {
        width: 360px;
      }
    }
  }
  &_body {
    &__title,
    &__value {
      &.__big {
        width: 360px;
      }
    }
    &__item {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &.__bold {
        div {
          font-weight: 700;
        }
      }
    }
  }
}

.management-table_wrap {
  .custom-table_body__value {
    &:nth-child(2) {
      font-weight: 700;
    }
  }
}

.finance{
  &-section_wrapper{
  }
  &-table_wrapper{
    &:not(:last-child){
      margin-bottom: 30px;
    }
    .ant{
      &-table{
        &-thead{
          .ant-table-cell{
            font-weight: 700;
          }
        }
        &-row{
          &:hover{
            background-color: transparent;
            .ant-table-cell{
              background-color: transparent;
            }
          }
        }
        &-cell{
          padding: 0 0 10px 0;
          border-bottom: 0;
          background-color: transparent;
        }
      }
    }
  }
}