.tab {
  border: none;
  list-style: none;
  font-size: 16px;
  text-transform: uppercase;
  color: $mid-gray;
  background: transparent;
  padding: 0 0 10px 0;
  margin-right: 38px;
  a {
    color: #000000;
    span {
      margin-left: 10px;
      color: $mid-gray;
    }
  }
  @include for-size(md) {
    font-size: 14px;
  }

  &:last-child {
    margin-right: 0;
  }
  &[disabled]{
    color: $light-gray;
    cursor: not-allowed;
    a{
      color: $light-gray;
      cursor: not-allowed;
    }
    &:hover{
      color: $light-gray;
      cursor: not-allowed;
      a{
        color: $light-gray;
        cursor: not-allowed;
      }
    }
  }
  &__active {
    border-bottom: 2px solid $link !important;
    color: $black !important;
    font-weight: bold;
    &[disabled]{
      border-bottom-color: $light-gray !important;;
    }
  }

  &__bold {
    color: $black !important;
    font-weight: 700;
  }
}
