.our-contracts {
  background: $white;
  border-radius: 3px;
  &-table-title {
    margin-top: 50px;
  }
  .empty-data {
    text-align: center;
    font-weight: 700;
  }
  .content-block {
    &_header {
      margin-bottom: 0px;
      padding: 40px 45px;
      svg {
        margin: 2px 20px 0px 0px;
        width: 40px;
        height: 36px;
      }
    }
    &_wrapper {
      min-height: 50vh;
      .not-bold h3{
        font-weight: 400;
      }
    }
    &_title {
      line-height: 31px;
      font-weight: 800;
    }
  }
  .requests-card {
    &_head {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      &__empty {
        &.__wrapper {
          width: 42%;
        }
      }
      &__name {
        &.__wrapper {
          width: 21%;
        }
      }
    }
    &_body {  
      padding: 5px 10px 10px 10px;
      &__column {
        &.__wrapper {
          width: 20%;
          padding-top: 5px;
          font-size: 14px;
          line-height: 25px;
          align-items: flex-start;
          &.date {
            font-size: 12px;
          }
        }
      }
    }
  }
}