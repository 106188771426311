.load-docs {
  &_wrapper {
    display: flex;
    flex-flow: row wrap;
    margin-top: 40px;
    &.__lineTop{
      padding-top: 40px;
      border-top: 1px dotted #D8D8D8;
    }
  }
}

.load-document-column{
  &__wrapper{
    margin-bottom: 40px;
    max-width: 400px;
    min-width: 260px;
    &:not(:last-child){
      margin-right: 80px;
    }
    &.__not-wide {
      max-width: 250px;
      min-width: 145px;
    }
  }
  &__title{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    svg, img{
      flex: 0 0 auto;
      width: 20px;
      margin-right: 10px;
    }
    &.__left {
      margin-left: -25px;
    }
  }
  &__item{
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    svg, img{
      flex: 0 0 auto;
      width: 20px;
      margin-right: 10px;
      margin-top: 6px;
      fill: $link;
    }
    &:hover{
      .load-document-column__btn-delete{
        opacity: 1;
      }
    }
  }
  &__link{
    word-break: break-word;
    color: $link;
    padding: 5px 0;
    flex: 1 1 auto;
    &:hover{
      text-decoration: underline;
    }
  }
  &__upload{
    display: block;
    margin-top: 40px;
  }
  &__btn-delete{
    flex: 0 0 auto;
    margin-left: 15px;
    padding: 0;
    transition: .2s;
    opacity: .4;
  }
  &__btn-add{

  }
  &__select {
    border-radius: 3px;
    height: 36px;
    min-width: 200px;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    span {
      height: 36px;
      line-height: 36px !important;
    }
  }
  &__selected{
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $green;
    svg, img{
      margin-right: 10px;
    }
  }
}
