@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
}

@media (min-width: 320px) and (max-width: 767.98px) {
  .header {
    &_profile {
      &__select {
        display: none;
      }
      &__name {
        display: none;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 991.98px) {
  .header {
    padding: 8px 15px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
    z-index: 99;
    &_btn__showSidebar {
      display: flex;
      opacity: 1;
    }
  }

  .main-sidebar {
    &_wrapper {
      position: fixed;
      min-width: 280px;
      width: auto;
      height: 100%;
      padding: 30px 25px;
      top: 0;
      background-color: $white;
      z-index: 101;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
      transition: left 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
      &.disabled {
        left: -100%;
      }
      &.showSidebar {
        left: 0;
      }
    }
    &_list {
      margin-top: 30px;
    }
    &_section {
      padding: 20px 0px 0px 18px;
    }
    &_btn {
      &__close {
        display: block;
      }
    }
  }
}
