.custom-switch-check{
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $green;
    background-color: $green;
  }
  &.ant-switch-checked{
    border-color: $green;
    background-color: $green;
  }
  &:focus{
    box-shadow: none !important;
  }
}

.custom-switch {
  &_wrapper {
    display: flex;
    align-items: center;
  }
  &_label {
    font-size: 12px;
  }
}