.current-messages{
  &.__wrapper{

  }

  &_chat{
    &.__wrapper{
      width: 50%;
    }
    &.__title{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 600;
      img, svg{
        width: 20px;
        margin-right: 20px;
      }
    }
    &.__sub-title{
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 1;
      span{
        padding-left: 10px;
        color: #A9A9A9;
      }
    }
    &.__messages{

    }
    &.__item{
      display: flex;
      &:not(:last-child){
        margin-bottom: 20px;
      }
      .message-chat_item{
        &_initials{
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1 0 auto;
          width: 40px;
          height: 40px;
          margin-right: 10px;
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          border: 2px solid #FFFFFF;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
          border-radius: 50%;
          overflow: hidden;
        }
        &_message{
          &.__wrapper{
            flex: 0 1 auto;
            width: 100%;
            background: rgba(#D8D8D8, .4);
            border-radius: 4px;
            padding: 15px;
          }
          &.__time{
            margin-bottom: 5px;
            font-size: 12px;
            color: rgba(0,0,0,0.4);
          }
          &.__text{
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
      & + ._user{
        .message-chat_item_initials.__wrapper{
          background-color: $green;
          color: #ffffff;
        }
      }
    }

    &._global-chat{
      &.__wrapper{
        padding-right: 40px;
        border-right: 1px dotted $light-gray;
      }
    }
    &._private-chat{
      &.__wrapper{
        padding-left: 40px;
      }
      .current-messages_chat{
        &.__messages{
          padding: 20px;
          background-color: #fff;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
        }
      }
    }
  }
}
