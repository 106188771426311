.work-orders {
  width: 82%;
  background: $white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  padding: 20px 40px;
  margin-bottom: 40px;
  min-height: 600px;
  @include for-size(l) {
    width: 100%;
    overflow: scroll;
  }
  @include for-size(md) {
    width: 100%;
    padding: 20px;
  }
  a {
    color: $black;
    text-decoration: none;
  }

  .header {
    background: $off-white;
  }

  &__body {
    display: flex;
    background: $off-white;
  }

  &__side-bar-btn {
    background: transparent;
  }

  &__content {
    @include for-size(md) {
      width: 50em;
    }
  }

  &__tablist {
    display: flex;
    padding: 0;
    margin-bottom: 91px;
    @include for-size(md) {
      margin-bottom: 60px;
    }
  }

  &__orders {
    margin-bottom: 40px;
  }

  &__titles {
    display: flex;
    margin-bottom: 50px;
    padding: 0 25px 0 65px;
    @include for-size(md) {
      margin-bottom: 20px;
    }
    &__number {
      min-width: 54px;
    }
    &__name {
      min-width: 160px;
      @include for-size(md) {
        min-width: auto;
      }
      button {
        background: transparent;
        border: none;
        padding: 0;
        &:after {
          content: url("../../images/svg/mark.svg");
          background-repeat: no-repeat;
          margin-left: 8px;
        }
      }
    }
  }

  &__titles-deadline {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    width: 80%;
    @include for-size(l) {
      width: 920px;
    }

    span:last-child {
      margin-left: 30px;
    }
  }
}

.orders-content {
  padding: 25px;
  width: 100%;
  background: transparent;
  border: none;
  span {
    color: $discrete-text;
  }
  &:hover {
    background: $link;
    color: $white;
    mark {
      color: $white !important;
      &:before {
        background: url("../../images/svg/map-marker-white.svg") !important;
      }
    }
    p {
      color: $white;
    }
    span {
      color: $white;
    }
    .ongoing {
      &__number {
        p {
          &:before {
            background: url("../../images/svg/clipboard-white.svg");
          }
        }
      }
    }
  }
  p {
    margin-bottom: 0;
  }
  font-size: 14px;
  .ongoing {
    display: flex;
    align-items: center;
    div {
      display: flex;
    }
    &__number {
      font-weight: bold;
      min-width: 96px;
      p {
        display: flex;
        &:before {
          content: "";
          background: url("../../images/svg/clipboard.svg") no-repeat;
          width: 20px;
          height: 20px;
          margin-right: 20px;
          display: inline-block;
        }
      }
    }
    &__status {
      font-weight: bold;
      text-transform: uppercase;
      min-width: 188px;
    }
    &__company {
      min-width: 140px;
    }
    &__deadlines {
      justify-content: space-between;
      width: 747px;
      mark {
        display: flex;
        padding: 0;
        color: $link;
        background: transparent;
        &:before {
          content: "";
          background: url("../../images/svg/map-marker.svg") no-repeat;
          width: 20px;
          height: 20px;
          margin-right: 21px;
          display: inline-block;
        }
      }
      span {
        &:first-child {
          margin-right: 40px;
        }
      }
    }
  }
  &__desc {
    p {
      margin-top: 13px;
      margin-left: 95px;
      text-align: left;
    }
  }
}
