.find-suppliers {
  margin-bottom: 40px;
  border-radius: 3px;
  a {
    color: $link;
  }
  &__language {
    margin-bottom: 45px;
  }

  &_tabs{
    &__wrapper{
      margin-bottom: 20px;
      .ant-tabs{
        overflow: visible;
        &-top {
          .ant-tabs-nav{
            margin-bottom: -1px;
            z-index: 1;
          }
        }
        &-nav{
          &:before{
            display: none;
          }
        }
        &-ink-bar{
          display: none;
        }
        &-tab{
          margin: 0;
          padding: 20px;
          color: #000;
          line-height: 1;
          &:hover{
            color: #000;
          }
          &-active{
            border-radius: 3px 3px 0 0;
            border-width: 1px;
            border-style: solid;
            border-width: 1px 1px 0 1px;
            border-color: $search-border;
            background: $off-white;
            
            .ant-tabs-tab-btn{
              color: #000 !important;
              font-weight: 700 !important;
            }
          }
          &-disabled{
            color: $black !important;
            opacity: 0.15;
            cursor: default !important;
            &:hover{
              opacity: 0.15;
            }
          }
        }
        &-content-holder{
          padding: 20px;
          border-radius: 0 3px 3px 3px;
          border: 1px solid $search-border;
        }
      }
    }
  }

  &_tab{
    &__item{
      outline: none !important;
      .first-setup_selection__item-wrap{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        outline: none !important;
        &.__with-dash {
          .blob-title {
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 20px;
          }
          .blob-item {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-end;
          }
        }
        .multi-select{
          margin-right: 50px;
          .ant-select.ant-select-multiple{
            min-height: 42px;
          }
          .ant-select-selector{
            background-color: transparent;
          }
        }
      }
    }
  }

  &__search-select {
    padding: 0 20px 20px 20px;
    position: relative;
  }
  &__inp-name {
    label {
      display: block;
      font-weight: bold;
      font-size: 14px;
      color: #000000;
      margin-bottom: 20px;
    }
    input {
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      width: 239px;
      height: 40px;
    }
  }
  &__card-links {
    background-color: $white;
    z-index: 5;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    padding: 40px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    box-sizing: unset;
    @include for-size(l) {
      padding-left: 40px;
    }
    @include for-size(md) {
      padding: 20px;
      margin-bottom: 0;
    }
    &__title {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      max-width: calc(100% - 80px);
      .btn-custom_close-modal {
        display: block;
      }
      @include for-size(md) {
        margin-top: 0;
      }
    }
    &__cards {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .card-settings {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: stretch;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
      border-radius: 3px;
      padding: 20px;
      margin-right: 20px;
      &__img {
        max-width: 100vw;
      }
      &__description {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        &.__full-card {
          margin-left: 20px;
        }
      }
      &__title {
        font-size: 14px;
        font-weight: 700;
        color: $link;
        margin-bottom: 10px;
        text-transform: uppercase;
        &.__full-card {
          color:rgba(0, 0, 0, 0.20);
        }
      }
      &__text {
        color: $black;
        margin: 0;
        &.__full-card {
          color:rgba(0, 0, 0, 0.20);
        }
      }
      button {
        margin-top: auto;
      }
      &__done-card {
        margin-top: auto;
        font-size: 18px;
        color: $green;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}
