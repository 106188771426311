.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  &__logo-wrapper {
    display: flex;
    align-items: center;
  }

  &_btn__showSidebar {
    display: none;
  }

  &__logo {
    background: url("../../../images/svg/handy-day.svg") no-repeat;
    width: 155px;
    height: 30px;
    background-size: cover;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__user-links {
    a {
      font-size: 16px;
      color: $black;
      outline: none;

      &:first-child {
        &:after {
          display: block;
          border-right: 1px dotted $light-gray;
        }
      }
    }
  }
}

.header {
  &_profile {
    &__wrapper {
      display: flex;
      align-items: center;
      .ant-select-selection-placeholder {
        color: $black;
      }
    }
    &__select {
      display: block;
      padding-right: 10px;
      padding-top: 2px;
      margin-right: 20px;
      margin-bottom: 0;
      border-right: 1px dotted $light-gray;
      .ant-select-selector {
        border: none !important;
        background: transparent !important;
        box-shadow: none !important;
      }
      .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
        cursor: auto;

        input {
          cursor: auto;
        }
      }

      .ant-select-selection-placeholder {
        padding-right: 20px;
        margin-right: 30px;

        &:after {
          content: "" !important;
          display: inline-block !important;
          position: absolute;
          top: 50%;
          transform: translateY(-10px);
          right: 10px;
          width: 20px !important;
          height: 20px;
          // background-image: url("/static/media/arrow-bottom.e1cfe366.svg");
          background-repeat: no-repeat;
          background-position-y: center;
          visibility: visible !important;
        }
      }

      .ant-select-disabled {
        .ant-select-selection-placeholder {
          padding-right: 0;
          margin-right: 0;
          &:after {
            content: "" !important;
            width: 0 !important;
            height: 0;
            display: none !important;
            visibility: hidden;
          }
        }
      }
    }
    &__name {
      display: block;
      margin-right: 20px;
      padding: 5px 20px 5px 0;
      color: $link;
      text-transform: lowercase;
      border: 0;
      box-shadow: none;
      background-color: transparent;
      cursor: pointer;
      border-right: 1px dotted $light-gray;
    }
    &__message {
      cursor: pointer;
      background-color: $red;
      box-shadow: none;
      border: 0;
      color: #fff;
      border-radius: 50px;
    }
    &__image {
      display: block;
      width: 40px;
      height: 40px;
      background-color: #fff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
      position: relative;
      border: 0;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      img {
        position: absolute;
        max-width: 110%;
        max-height: 110%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
    }
  }
}

.profile {
  display: flex;
  align-items: center;
  @include for-size(md) {
    align-items: flex-end;
    flex-direction: column;
    font-size: 11px;
  }
}

.first-setup-header {
  display: flex;
  justify-content: space-between;

  img {
    width: 155px;
    height: 30px;
    @include for-size(xs) {
      margin-bottom: 20px;
      width: 95px;
    }
  }

  &__info {
    display: flex;
    @include for-size(md) {
      flex-direction: column;
      p {
        margin: 0;
        font-size: 12px;
      }
    }

    p:first-child {
      border-right: 1px dotted $light-gray;
      padding-right: 20px;
      @include for-size(md) {
        border: none;
        padding: 0;
      }
    }

    p:last-child {
      margin-left: 20px;
      @include for-size(md) {
        margin: 0;
      }
    }
  }
}
