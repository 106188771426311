@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {

  .requests-to-us {
    &__search-select {
      padding: 20px;
    }
    &__selections {
      width: 100%;
    }
  }

}
