.profile-settings {
  display: flex;
  @include for-size(md) {
    flex-direction: column;
    align-items: flex-end;
  }

  a {
    color: $link;
    @include for-size(md) {
      font-size: 12px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    @include for-size(md) {
      margin-right: 10px;
    }

    span {
      color: $mid-gray;
      font-size: 10px;
      text-transform: uppercase;
      margin-left: auto;
    }
  }
  button {
    background: transparent;
    border: none;
    img {
      object-fit: contain;
      width: 30px;
    }
  }
}
