@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .find-suppliers {
    &__search-select {
      padding: 20px;
    }
    &__selections {
      width: 100%;
    }
    &__inp-name {
      width: 100%;
      .input {
        width: 100%;
      }
    }
  }

  .first-setup_selection {
    &__item-wrap {
      margin-right: 15px;
    }
  }
}
