@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .modal-settings {
    &__wrapper {
    }
    &__content {
    }
    &__tabs {
      align-items: flex-end;
      .tab {
        margin-right: 25px;
        text-align: center;
        a {
          line-height: 1.1;
          img,
          svg {
            display: block;
            margin: 0 auto 5px;
          }
        }
      }
    }
  }
}
