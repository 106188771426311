.invite-user {
  &__wrapper{
    max-width: 600px;
  }
  &__title{
    margin-bottom: 30px;
    text-transform: uppercase;
    font-weight: 700;
  }
  &_form {
    &__wrapper {
      margin-bottom: 50px;
      .btn-custom_wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding-right: 40px;
        .btn-custom_main{
          margin-right: 0;
        }
      }
    }
    &__item{
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 15px;
      padding-right: 40px;
      .form{
        width: 100%;
      }
      .input{
        width: 100%;
        background-color: #fff;
      }
      p{
        margin-bottom: 0;
      }
      .btn-custom__delete{
        position: absolute;
        top: 20px;
        right: 0;
      }
    }
  }
  &__table{
    &.__wrapper{
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      &.__preloader{
        .preloader500{
          height: auto;
        }
      }
    }
    &.__item{
      display: flex;
      align-items: center;
      padding: 10px 0;
      &:not(:last-child){
        border-bottom: 1px solid #d8d8d8;
      }
      &:hover{
        .invite-user__table.__actions{
          .btn-custom__delete{
            opacity: 1;
          }
        }
      }
    }
    &.__value{
      width: 40%;
      span{
        color: #A9A9A9;
        padding-right: 5px;
      }
    }
    &.__actions{
      display: flex;
      justify-content: flex-end;
      flex: 1 0 auto;
      .btn-custom__delete{
        display: flex;
        align-items: center;
        opacity: 0.6;
        transition: .2s;
      }
    }
  }
}