.presentation-page-header {
  display: flex;
  margin-bottom: 30px;
  &__logo {
    position: relative;
    width: 160px;
    height: 160px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    margin: 0 auto 20px auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    &:hover {
      .presentation-card__edit-btn,
      .btn-custom__delete {
        background-color: rgba(#fff, 0.9);
      }
    }
    button {
      margin: 0 auto;
    }
    span {
      color: $discrete-text;
    }
    .presentation-card__edit-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 5px;
      right: 0;
      z-index: 3;
      padding: 10px;
      transition: 0.3s;
      border-radius: 50px;
    }
    .btn-custom__delete {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
      padding: 10px;
      transition: 0.3s;
      border-radius: 50px;
    }
  }

  &__favorite {
    border-right: 1px dotted $light-gray;
    padding: 0 20px;

    button {
      background: transparent;
      border: none;
      cursor: pointer;

      img {
        width: 40px;
      }
    }
  }

  &__left-side {
    width: 20%;
    min-width: 200px;
    &.presentation-header-edit {
      padding: 25px 20px;
      border-radius: 3px;
      border: 1px dotted #a9a9a9;
    }
  }

  &__right-side {
    padding-left: 60px;
    width: 80%;

    &.presentation-header-edit {
      .presentation-header-edit_inner-wrapper{
        padding: 25px 30px;
        border-radius: 3px;
        border: 1px dotted #a9a9a9;
      }
    }
  }

  &__titles {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
  }

  &__name {
    display: initial;
    font-size: 24px;
    border-right: 1px dotted $light-gray;
    padding-right: 20px;
    margin-bottom: 0;
    font-weight: bold;
    color: #000000;
    width: auto;
  }

  &__about-org {
    display: flex;
    align-items: center;
  }

  &__not-edit {
    box-shadow: none;
    background: transparent;
    padding-left: 0;
  }

  &__input {
    height: 44px;
    color: #000000;
    width: 135px;
  }

  &__input-name {
    font-weight: bold;
    font-size: 24px;
    height: 37px;
    color: #000000;
    width: auto;
    margin-bottom: 0;
  }

  &__input-description {
    display: block;
    margin-bottom: 15px;
    padding: 10px 20px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 60px;
    max-height: 200px;
    background: $white;
    border: none;
    font-size: 14px;
    color: $discrete-text;
    line-height: 17px;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    outline: none;
  }
  &__input-description.request {
    min-height: 120px;
    max-height: 300px;
    margin-bottom: 0px;
  }

  &__input-site {
    height: 44px;
  }

  &__info-org {
    border-right: 1px dotted $light-gray;
    padding: 0 20px;
  }

  &__actions {
    display: flex;
    align-items: center;
    button {
      cursor: pointer;
    }
  }

  &__select {
    padding-left: 20px !important;
    span {
      cursor: pointer;
    }
    .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:before {
        content: url("../../../../../assets/images/svg/ellipsis.svg");
        height: 20px;
      }
    }
    .ant-select-selection-item {
      color: $link;
    }
  }

  &__select-list {
    padding-top: 10px;
    padding-bottom: 10px;
    .ant-dropdown-menu-item {
      padding: 10px;
      display: flex;
      align-items: center;
      img {
        width: 19px;
      }
      span {
        padding-left: 5px;
        color: $link;
        font-size: 14px;
      }
    }
  }

  &__page-link {
    color: $link;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    svg {
      margin-right: 10px;
      fill: $link;
      color: $link;
    }
    &:hover {
      color: #40a9ff;
      svg {
        fill: #40a9ff;
        color: #40a9ff;
      }
    }
    &.__no-link {
      color: $mid-gray;
      svg {
        color: $mid-gray;
        fill: $mid-gray;
      }
    }
  }
}

.presentation-page {
  display: flex;

  &__left-side {
    width: 20%;
    min-width: 200px;

    &__cards {
      padding: 20px;
      border-top: 1px dotted $light-gray;
      text-align: center;

      h5 {
        font-size: 12px;
        font-weight: 700;
      }

      strong {
        font-weight: 800;
        font-size: 24px;
        display: block;
      }

      span {
        font-size: 10px;
        color: $mid-gray;
        display: block;
      }
    }

    &__credit-rating {
      margin-top: 20px;
    }
  }

  &__quick-facts {
    margin: 20px auto 0 auto;
    text-align: left;
    width: 50%;
    min-width: 170px;
  }

  &_quick-fact {
    &.__item{
      margin: 0 10px 15px;
      display: flex;
      align-items: center;
    }
    &.__title{
      font-size: 12px;
      line-height: 130%;
      color: $light-gray;
      &._1{
        color: $green;
      }
    }
    &.__icon {
      display: flex;
      align-items: center;
      width: 22px;
      margin-right: 15px;
    }
  }

  &__right-side {
    padding-left: 60px;
    width: 80%;

    &__tabs {
      margin-bottom: 74px;
    }
  }

  &__non-payment {
    color: $red;
  }
}

.avatar-uploader {
  width: 100%;
  height: 100%;
  .ant-upload.ant-upload-select {
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
  }
  .upload-btn_wrap {
    border-radius: 3px;
    border: 1px solid $link;
    padding: 12px 15px;
    color: $link;
    img {
      padding-right: 10px;
    }
  }
}

.rating-pie {
  &_wrapper {
    margin-top: 20px;
  }
  &_pie {
    width: 160px;
    max-width: 100%;
  }
  &_pointer {
    position: relative;
    width: 40px;
    height: 40px;
    margin: -20px auto 0;
    border-radius: 0 50% 50% 50%;
    background-color: $light-gray;
    transform: rotate(-135deg);
    i {
      position: absolute;
      font-style: inherit;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      color: #000000;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(135deg);
    }
    &_1 {
      background-color: $red;
      transform: rotate(-20deg);
      i {
        color: $white;
        transform: translate(-50%, -50%) rotate(20deg);
      }
    }
    &_2 {
      background-color: $orange;
      transform: rotate(20deg);
      i {
        color: $white;
        transform: translate(-50%, -50%) rotate(-20deg);
      }
    }
    &_3 {
      background-color: $link;
      transform: rotate(65deg);
      i {
        color: $white;
        transform: translate(-50%, -50%) rotate(-65deg);
      }
    }
    &_4 {
      background-color: $green;
      transform: rotate(110deg);
      i {
        color: $white;
        transform: translate(-50%, -50%) rotate(-110deg);
      }
    }
  }
  &_question{
    margin: -20px 0 0 0;
    svg{
      width: 30px;
      height: 30px;
    }
  }
}
