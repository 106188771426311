.services-offered {
  &_item {
    width: calc(33% - 20px);
    max-width: 340px;
    min-width: 310px;
    margin-bottom: 20px;
    margin-right: 20px;
    border: 1px dotted$mid-gray;
    padding: 20px;
    &__inner {
      display: flex;
      align-items: flex-end;
    }
    .services-field_wrap {
      width: 100%;
    }
    .services-action_wrap {
      margin-left: 15px;
    }
    .field_wrapper {
      display: flex;
      align-items: flex-start;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &:last-child {
        p {
          margin-bottom: 0;
        }
      }
      .field {
        width: 100%;
      }
      img {
        width: 16px;
        display: inline-block;
        margin-right: 10px;
        padding-top: 13px;
      }
      input {
        width: 100%;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
      }
      p {
        margin-bottom: 10px;
      }
    }
  }
}
