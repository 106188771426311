.pagination-static {
  &_wrapper {
    .pagination {
      display: flex;
      flex-wrap: wrap;
      padding-left: 15px;
      .page-item {
        list-style-type: none;
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
        border-left: 1px solid $light-gray;
        background-color: #fff;
        transition: 0.2s;
        &:first-child {
          border-radius: 3px 0 0 3px;
        }
        &:last-child {
          border-radius: 0 3px 3px 0;
          border-right: 1px solid $light-gray;
        }
        &.active {
          background-color: $link;
          border-color: $link;
          .page-link {
            color: #fff;
          }
          &:hover {
            background-color: $link;
          }
        }
        &.disabled {
          .page-link {
            cursor: not-allowed;
          }
          &:hover {
            background-color: #fff;
          }
        }
        &:hover {
          background-color: rgba($light-gray, 0.5);
        }
        .page-link {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $link;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
