.drawer-component-content {
  .ant-drawer-content-wrapper {
    width: 95% !important;
  }
  &.admin_header__drawer{
    .ant-drawer-content-wrapper{
      width: 50% !important;
      min-width: 280px;
    }
  }
}