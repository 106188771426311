.advertisements-table {
  .id-column {
    width: 15%;
  }
  .name-column {
    width: 15%;
  }
  .link-column {
    width: 20%;
  }
}
