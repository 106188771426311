.content-tag {
  &_title {
    font-size: 12px;
    font-weight: 700;
  }
  &_tag {
    border-radius: 3px !important;
    height: 22px !important;
    font-size: 10px !important;
    line-height: 20px !important;
    font-weight: 700;
  }
  &_tag.ant-tag-checkable {
    &-checked {
      background-color: $green !important;
      color: $white;
      &:focus,
      &:active,
      &::selection {
        border-color: $green !important;
        background-color: $green !important;
        outline-color: $green !important;
      }
    }
    border-color: $green;
    color: $green;
    user-select: none;
    &:focus,
    &:active,
    &::selection {
      border-color: $green !important;
      background-color: $green !important;
      outline-color: $green !important;
      color: $white !important;
    }
  }
}

.closable-tags {
  &_wrapper {
    display: flex;
    flex-flow: row wrap;
  }
  &_tag.ant-tag {
    border-radius: 20px;
    border: none;
    background-color: $link;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    font-weight: 400;
    color: $white;
    padding: 0px 5px 0px 15px;
    margin: 0px 10px 10px 0px;
    img.real-icon {
      margin: -4px 0px 0px 10px;
      cursor: pointer;
    }
    .anticon-close {
      line-height: 20px;
      height: 20px;
      width: 20px;
      vertical-align: -5px;
      margin: 0px 5px 0px -20px;
      border-radius: 10px;
      svg {
        display: none;
      }
    }
  }
}
