.addable-field {
  &_wrapper {
    width: 236px;
    flex-shrink: 0;
  }
  &_input {
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px !important;
    height: 40px;
    border: none !important;
    &:hover,
    &:active {
      border: none !important;
    }
    input {
      font-size: 16px !important;
      padding: 0px 5px 0px 10px !important;
      border-radius: 10px !important;
    }
  }
  &_button {
    border: none !important;
    height: 20px !important;
    min-width: 20px !important;
    width: 20px !important;
    padding: 0px !important;
    margin-right: 5px;
    img {
      margin-top: -10px;
    }
  }
}