.admin_authentication {
  &__wrapper {
    background-color: #efefef;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .react-tel-input.react-number-input {
      .react-number-input-search.form-control {
        width: 100%;
        height: 60px;
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
        border: transparent;
      }

      .react-number-btn {
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        border: transparent;
        .selected-flag {
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  &__content {
    &.__wrapper {
      flex: 1 1 auto;
      margin-top: 10vh;
      margin-bottom: 50px;
    }
  }

  &__title {
    font-size: 2.75rem;
    margin: 0 0 30px;
    font-weight: 700;
    text-align: center;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 0;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__form {
    &.__wrapper {
      margin: 0 auto;
      max-width: 430px;
      background-color: #fff;
      padding: 40px 20px;
      border-radius: 5px;
    }

    &.__actions {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      button {
        min-width: 130px;
        justify-content: center;
        padding: 20px 40px;
        height: auto;
      }
    }
    &.__info{
      margin-top: 20px;
      text-align: center;
    }
    & .counter {
      color: #1ba2cc;
    }
  }

  &__steps {
    &.__numbers {
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }

  &__code {
    .__fields {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}