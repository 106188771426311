.message {
  display: flex;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  &__img-profile {
    margin-right: 16px;
  }
  &__user {
    background: #f3f3f3;
    padding: 16px;
    border-radius: 3px;
    span {
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
      line-height: 17px;
    }
  }
}
