.identify-owner{
  &__wrapper{

  }
  &__container{
    max-width: 400px;
    margin: 0 auto;
  }
  &__title{
    margin-bottom: 40px;
  }
  &__form{
    .field {
      margin-bottom: 40px;
      input{
        width: 100%;
      }
    }
    .btn-custom{
      &_wrap{
        display: flex;
      }
      &_main{
        min-width: 280px;
        height: 60px;
        justify-content: center;
      }
    }
  }
}